import React from 'react';
import {XAxis, YAxis, Tooltip, CartesianGrid, Area, ResponsiveContainer, AreaChart} from 'recharts';

const data = [
  {
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export const PayoutChart = () => {
  return (
    <div style={{width: '100%'}}>
      <ResponsiveContainer width="100%" height={250}>
        <AreaChart
          width={500}
          height={200}
          data={data}
          syncId="anyId"
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey="name"/>
          <YAxis/>
          <Tooltip/>
          <Area type="monotone" dataKey="pv" stroke="#4169E1" fill="#4169E11F"/>
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};