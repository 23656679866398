import React, { useEffect, useRef, useState } from "react";

import arrowDropDown from "./images/arrow-drop-down-sort.svg";

export type CampaignSelectPropsType = {
  label: string;
  filter: string;
  options: { value: number | string; label: string }[];
  onChangeFilter: (filter: string, value: number | string) => void;
};

export const StatisticSelect = ({
  label,
  filter,
  options,
  onChangeFilter,
}: CampaignSelectPropsType) => {
  const selectRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(label);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !(selectRef.current as unknown as HTMLElement).contains(
        event.target as Node
      )
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (document) {
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, []);

  return (
    <div ref={selectRef} className="bg-white w-full h-34 relative">
      <div
        className="bg-white flex py-1 px-4 justify-center items-center border rounded-md border-dark-blue cursor-pointer"
        onClick={() => setOpen((open) => !open)}
      >
        <span className="bg-white text-xxs font-normal text-dark-blue">
          {name}
        </span>
        <img src={arrowDropDown} alt="arrow-down" />
      </div>

      {open && (
        <div className="overflow-y-scroll bg-white absolute top-11 z-20 w-full h-268 py-2 px-4 border rounded-xxs">
          {options.map((el) => (
            <div
              key={el.value}
              onClick={() => {
                setName(el.label);
                setOpen(false);
                onChangeFilter(filter, el.value);
              }}
              className="bg-white cursor-pointer text-xxs font-normal text-dark-blue mb-1"
            >
              {el.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
