import * as React from "react";
import {
  deleteTargetUrlCampaigns,
  getTargetUrlCampaigns,
} from "../../../../services/TargetUrlCamaigns";
import { getAllProductPages } from "../../../../services/m30_products/pages/ProductPagesService";
import { getDictionary } from "../../../../helpers/DisctionariesHelper";
import { getProductImages } from "../../../../services/m30_products/images/ProductImagesService";
import { getProducts } from "../../../../services/m30_products/ProductsService";
import { IDictionaryValue } from "../../../../components/templateForm/types/dictionaries/IDictionaryValue";
import { IId } from "../../../../common/interfaces/IId";
import { IOfferDetails } from "../interfaces/IOfferDetails";
import { IOfferDetailsCampaign } from "../interfaces/IOfferDetailsCampaign";
import { IProductImageFilters } from "../../../../services/m30_products/images/interfaces/IProductImageFilters";
import { IProductPagesFilters } from "../../../../services/m30_products/pages/interfaces/IProductPagesFilters";
import { openSuccessNotification } from "../../../../helpers/NotificationHelper";
import { ProductCardOffer } from "../components/productCardOffer/ProductCardOffer";
import { resources } from "../../../../common/Resources";
import { Spin } from "antd";
import { TargetUrl } from "../tab/TargetUrl";
import { TermsAndConditions } from "../tab/TermsAndConditions";

export class OfferDetailsContent extends React.Component<IId> {
  _isMounted = false;

  state = {
    img: undefined,
    showTargetUrlTable: false,
    productData: {} as IOfferDetails,
    countriesD: Array<IDictionaryValue>(),
    currencyD: Array<IDictionaryValue>(),
    categories: Array<IDictionaryValue>(),
    campaigns: Array<IOfferDetailsCampaign>(),
    loading_campaigns: false,
    landings: [],
    landings_archived: [],
    pagesList: [],
    showTabContent: false,
  };

  componentDidMount() {
    this._isMounted = true;

    this.getDictionaries();
    this.getProduct();
    this.getPagesList();
    this.getLandingsList();
    this.getCampaigns();
    this.getProductImg();
  }

  onHandlerClick(newValue: boolean) {
    this.setState({ showTabContent: newValue });
  }

  componentWillUnmount = () => (this._isMounted = false);

  public render() {
    return (
      <section className="container-fluid h-full xm:bg-white md:bg-transparent mb-16 pt-10 px-6 md:px-8">
        {Object.keys(this.state.productData).length > 0 ? (
          <>
            <ProductCardOffer
              img={this.state.img}
              productData={this.state.productData}
              countriesD={this.state.countriesD}
              currencyD={this.state.currencyD}
              categories={this.state.categories}
            />

            <div className="md:pl-10 xm:pl-none pb-0 md:pb-12 rounded-b-xs xm:bg-white">
              <div className="flex gap-1 mb-10">
                <span className="xm:text-md text-dark-blue xm:font-semibold sm:font-normal min-w-max sm:text-sm -mb-2">
                  Product information
                </span>
                <span className="border-light-white-gray border-b w-full xm:hidden md:block"></span>
              </div>

              <div className="flex gap-5">
                <button
                  onClick={() => this.onHandlerClick(true)}
                  className={`${
                    this.state.showTabContent ? "activeTab" : "unActiveTab"
                  } rounded-md border border-dark-blue py-1 px-5 text-dark-gray text-xxs text-normal`}
                >
                  {resources.offersDetails.landings_tab}
                </button>
                <button
                  onClick={() => this.onHandlerClick(false)}
                  className={`${
                    !this.state.showTabContent ? "activeTab" : "unActiveTab"
                  } rounded-md border border-dark-blue py-1 px-5 text-dark-gray text-xxs text-normal`}
                >
                  {resources.offersDetails.terms_and_conditions}
                </button>
              </div>
              {this.state.showTabContent ? (
                <TargetUrl
                  id={this.props.id}
                  reRenderTable={this.getCampaigns}
                  product={this.state.productData}
                  pages={this.state.pagesList}
                  campaigns={this.state.campaigns}
                  deleteTargetUrlCampaigns={this.deleteTargetUrlCampaigns}
                />
              ) : (
                <TermsAndConditions
                  terms_en={this.state.productData.terms_en}
                  terms_ru={this.state.productData.terms_ru}
                  terms_id={this.state.productData.terms_id}
                  conditions_en={this.state.productData.conditions_en}
                  conditions_ru={this.state.productData.conditions_ru}
                  conditions_id={this.state.productData.conditions_id}
                />
              )}
            </div>
          </>
        ) : (
          <div className="text-center">
            <Spin tip={resources.spin.loading} />
          </div>
        )}
      </section>
    );
  }

  changeFlag = (value: boolean): void => {
    this.setState({ showTargetUrlTable: value });
  };

  deleteTargetUrlCampaigns = (id: string) => {
    deleteTargetUrlCampaigns(id).then((response) => {
      if (response) {
        this.markCampaignAsDelete(id);
        openSuccessNotification(resources.statement.deleted);
      }
    });
  };

  markCampaignAsDelete = (id: string) => {
    const { campaigns } = this.state;
    const index = campaigns.findIndex((x) => x.id === id);

    campaigns[index].deleted_at = new Date().toString();

    this.setState({ campaigns });
  };

  getProductImg = () => {
    const object: IProductImageFilters = {
      is_main: true,
    };

    getProductImages(Number(this.props.id), object).then((response) => {
      const obj = response !== null ? response : [];
      obj.length && this._isMounted && this.setState({ img: obj[0].src });
    });
  };

  getProduct() {
    this.setState({ loading: true });

    const object = {
      columns: [
        "id",
        "categories",
        "name_short",
        "default_payout",
        "default_payout_schema",
        "default_payout_idr",
        "approve_page",
        "default_landing_price",
        "country_id",
        "description_en",
        "description_ru",
        "description_id",
        "traffic_sources_en",
        "traffic_sources_ru",
        "traffic_sources_id",
        "description_short_en",
        "description_short_ru",
        "description_short_id",
        "terms_en",
        "terms_ru",
        "terms_id",
        "conditions_en",
        "conditions_ru",
        "conditions_id",
        "product_sku",
      ],
      id: this.props.id,
    };

    getProducts(object)
      .then((response) => {
        if (response[0]) this.setState({ productData: response[0] });
      })
      .finally(() => this.setState({ loading: false }));
  }

  getDictionaries() {
    this.setState({ loading: true });

    this.setState({
      countriesD: getDictionary("countriesNameD"),
      categories: getDictionary("categoriesD"),
      currencyD: getDictionary("currencyD"),
      loading: false,
    });
  }

  getPagesList() {
    const object: IProductPagesFilters = {
      columns: [
        "id",
        "name",
        "url",
        "is_prelanding",
        "is_mobile",
        "is_desktop",
        "is_top",
        "deleted_at",
      ],
      is_visible: true,
    };

    getAllProductPages(Number(this.props.id), object)
      .then((response) => {
        const pagesList = response.filter((x: any) => x.deleted_at === null);
        this.setState({ pagesList });
      })
      .finally(() => this.setState({ loading: false }));
  }

  getLandingsList = () => {
    const object: IProductPagesFilters = {
      columns: ["id", "name", "url", "deleted_at"],
    };

    getAllProductPages(Number(this.props.id), object).then((response) => {
      const landings = response.filter((x: any) => x.deleted_at === null);
      this.setState({ landings });
    });
  };

  getCampaigns = async () => {
    this.setState({ loading_campaigns: true });
    await getTargetUrlCampaigns(`?productId=${this.props.id}`)
      .then((response) => {
        this.setState({
          campaigns: response.map((el: any, index: number) =>
            this.generateCampaignData(el, index)
          ),
        });
      })
      .finally(() => {
        this.setState({ loading_campaigns: false });
      });
  };

  generateCampaignData = (campaign: any, index: number) => {
    return {
      id: campaign.uuid,
      key: index,
      name: campaign.name,
      lp: campaign.config.landingPages,
      pre: campaign.config.preLandingPages,
      url: campaign.urls.tracker,
      deleted_at: campaign.deletedAt,
    };
  };
}
