import LocalizedStrings from "react-localization";

export const _onSetLanguage = (lang: string) => {
  resources.setLanguage(lang);
};

export const resources = new LocalizedStrings({
  en_GB: {
    validation: {
      current_password_is_incorrect: "Current password is incorrect",
      // Name
      nameIsRequired: "Name is required",
      // Last name
      lastNameIsRequired: "Last name is required",
      // E-mail
      emailIsRequired: "E-mail is required",
      incorrectEmailAdres: "Incorrect e-mail adress",
      requiredUtf8Characters: "Required utf-8 characters",
      // Password
      passwordIsRequired: "Password is required",
      confirmPasswordIsRequired: "Confirm password is required",
      requiredTheSamePasswords: "Required the same passwords",
      passwordCondition:
        "Password must contain at least 1 upper case letter, 1 lower case letter, 1 numeric character, at least 2 special characters and must contain at least 8 characters and max 32 characters.",
      // SkypeId
      skypeIdIsRequired: "Skype ID is required",
      // Telegram
      telegramIsRequired: "Telegram is required",
      // Country
      countryIsRequired: "Country is required",
      // Rest
      requiredMin3Characters: "Required min 3 characters",
      requiredMin8Characters: "Required min 8 characters",
      requiredMax15Characters: "Required max 15 characters",
      requiredMax20Characters: "Required max 20 characters",
      requiredMax30Characters: "Required max 30 characters",
      requiredMax50Characters: "Required max 50 characters",
      requiredMax191Characters: "Required max 191 characters",
      requiredMax250Characters: "Required max 250 characters",
      requiredMax255Characters: "Required max 255 characters",
      requiredAlphaNumeric: "Required alphaNumeric",
      statusIsRequired: "Status is required (number)",
      requiredMax11Numbers: "Required max 11 numbers",
      required: "Field is required",
      invalidDomain: "Domain invalid",
      duplicatedParam: "Duplicated parameter name",
      invalidParamName: "Invalid parameter name",
      invalidParamValue: "Invalid parameter value",
      invalidUrl: "Invalid URL",
      customInvalidUrlError:
        "IMPORTANT: This URL seems invalid but you can still save it.",
    },
    statistics: {
      callCenterTitle: "Call center classifiers",
      totalDescription:
        "Validated, Send to Call Center, Awaiting, Rejected, Excluded and Sold",
      awaitingDescription:
        "Classification pending (called leads by Call Center)",
      rejectedDescription: "Called but rejected",
      soldDescription: "Verified sales",
      orderCreatedDescription: "Awaiting verification",
      trashDescription: "Wrong numbers etc.",
      excludedDescription: "Doubles and not contacted",
      validatedDescription:
        "Validated by system but not sent to call center yet",
      sendToCcDescription: "Sent to call center but not called yet",
      doubledDescription: "Marked by system",
      verifiedApprovrdRateDescription: "((Order Created + Sold) / Total) * 100",
      approvedRateDescription: "(Sold / Total) * 100",
      leads: "Leads",
      total: "Total",
      awaiting: "Awaiting",
      rejected: "Rejected",
      approved: "Approved",
      approveRate: "Approve rate",
      payoutAmount: "Payout amount",
      sold: "Sold",
      orderCreated: "Order Created",
      trash: "Trash",
      excluded: "Excluded",
      validated: "Validated",
      sendToCC: "Send to Call Center",
      sentcc: "Sent to CC",
      double: "Double",
      verifiedApprovrdRate: "Approve Rate (Potential)",
      approvedRate: "Verified Approve Rate",
      unrecognized: "Unrecognized",
      noMoney: "No money",
      noMoneyDescription: "No money Description",
      awaitingAll: "Awaiting all",
      awaitingAllDescription: "New lead, Validated, Awaiting, Send to CC",
      title: "Statistics leads",
      summary: "Summary statistics",
      numberOfLeadsByOffer: "Number of leads by offer",
      percentageOfApprovedLeads: "Percentage of approved leads",
    },
    tabPane: {
      details: "Details",
      summary: "Summary",
      personalData: "Personal data",
      changePassword: "Change password",
      notifications: "Notifications",
      apiToken: "API Token",
      userSettings: "User settings",
      wallet: "Wallet",
      payoutHistory: "Payout history",
      globalPostbacks: "Global postbacks",
      registrationLink: "Registration link",
    },
    buttons: {
      upload: "Upload",
      yes: "Yes",
      no: "No",
      confirm: "Confirm",
      today: "Today",
      yesterday: "Yesterday",
      thisMonth: "This month",
      lastMonth: "Last month",
      last30Days: "Last 30 days",
      resetFilters: "Clear filters",
      addFilter: "Add filter",
      removeFilter: "Remove filter",
      clearAll: "Clear all",
      submit: "Submit",
      search: "Search",
      cancel: "Cancel",
      refreshTable: "Refresh table",
      updateCampaign: "Update campaign",
      reset: "Reset",
      register: "Register",
      create: "Create",
      login: "Login",
      save: "Save",
      save_new_password: "Save new password",
      send: "Send",
      apply: "Apply",
      generateToken: "Generate token",
      addNewEmployee: "Add new employee",
      addNewProduct: "Add new product",
      add_new: "Add new",
      filter: "Filter",
      addVariable: "Add variable",
      goToMainPage: "Go to main page",
      download: "Download",
      exportXlsx: "Export XLSX",
      downloading: "Downloading",
      new: "New",
      switchToUser: "Switch To",
      backToAdminAccount: "Back To Admin",
      setPostbackForAllProducts: "Set this postbacks for all products",
      show: "Show",
      hide: "Hide",
      enable: "Enable",
      disable: "Disable",
    },
    radioButtons: {
      leadsCreatedDate: "Lead created at date",
      leadsApprovedDate: "Lead approved at date",
      leadsSoldDate: "Lead sold at date",
      leadsCallCenterLastCallDate: "Lead call center last call date",
      salesCountryTimezone: "Sales country trimezone",
      myTimezone: "My timezone",
    },
    labels: {
      visibility: "Visibility",
      active_products: "Active products",
      inactive_products: "Inactive products",
      all_products: "All products",
      are_you_sure: "Are you sure?",
      do_you_want_to_generate_this_campaign:
        "Do you want to generate this campaign?",
      create_leads_on_order_delivered_status_global:
        "Create leads on order delivered status ( GLOBAL )",
      partner_token: "Partner token",
      warnings: "Warnings",
      fails: "Fails",
      success: "Successes",
      comment: "Comment",
      selected: "Selected",
      items: "items",
      global_actions: "Global actions",
      partners_that_registerd_from_your_ref_link:
        "Partners that registerd from your ref link: ",
      beauty: "Beauty",
      health: "Health",
      slimming: "Slimming",
      adult: "Adult",
      other: "Other",
      search: "Search",
      latest_news: "Latest News",
      top_offers: "Top offers",
      found: "Found",
      rows: "rows",
      balance: "Balance",
      hold: "Hold",
      your_manager: "Your manager",
      no_manager: "No manager",
      profile: "Profile",
      log_out: "Log Out",
      reportByTimezone: "Report by time zone",
      dateType: "Date type",
      dateRange: "Date range",
      dateFrom: "Date from",
      dateTo: "Date to",
      setQuickDate: "Set quick date",
      selectedFilters: "Selected filters",
      status: "Status",
      country: "Country",
      product: "Product",
      lead_type: "Lead type",
      partner: "Partner",
      userEmail: "User e-mail",
      payoutScheme: "Payout scheme",
      remarketingCampaign: "Remarketing campaign",
      classifierName: "Classifier name",
      accountManager: "Account manager",
      selectTimeZone: "Select time zone ",
      timezone: "Time zone",
      isActive: "Is active ",
      isExcluded: "Excluded from double",
      isOnline: "Is online ",
      type: "Type",
      name: "Name",
      emailAddress: "E-mail address",
      domain: "Domain",
      user: "User",
      password: "Password",
      repeatPassword: "Repeat password",
      walletName: "Wallet name",
      walletNumber: "Wallet number",
      baseUrl: "Base URL",
      id: "ID",
      email: "E-mail",
      skype: "Skype",
      telegram: "Telegram",
      object: "Object",
      message: "Message",
      section: "Section",
      phone: "Phone number",
      currentPassword: "Current password",
      newPassword: "New password",
      repeatNewPassword: "Repeat new password",
      apiToken: "API Token",
      click_id: "click_id",
      pub_id: "Pub ID",
      lp_url: "Lp URL",
      second_name: "Second name",
      short_name: "Short name",
      role: "Role",
      description: "Description",
      application_access: "Application access",
      pages: "Pages",
      products: "Products",
      addressLine1: "Adress line 1",
      addressLine2: "Adress line 2",
      city: "City",
      state: "State",
      zipCode: "ZIP code",
      isInternal: "Internal",
      partnersAwaitingActivation: "Partners awaiting activation",
      allPartners: "All Partners",
      company: "Company",
      basedOnRole: "Based on role",
      campaigns: "Campaigns",
      instances: "Instances",
      currency: "Currency",
      code: "Code",
      address: "Address",
      operative: "Operative",
      name_eng: "Name (eng)",
      bank_transfer: "Bank transfer",
      district: "District",
      subdistrict: "Subdistrict",
      island: "Island",
      courier: "Courier",
      post_code: "Post code",
      destination_code: "Destination code",
      lead_time: "Lead time",
      lead_time_text: "Lead time text",
      province: "Province",
      delivery_cost: "Delivery cost",
      return_cost: "Return cost",
      instance_fee: "Instance fee",
      cod_fee: "Cod fee",
      admin_fee: "Admin fee",
      is_qualified: "Is qualified",
      is_contacted: "Is contacted",
      affbay_status: "Affbay status",
      is_visible: "Is visible",
      is_top: "Is top",
      is_desktop: "Is deskop",
      is_mobile: "Is mobile",
      url: "URL",
      tracker_id: "Tracker ID",
      tracker_type: "Tracker type",
      focus_ID: "Focus ID",
      instance: "Instance",
      signature: "Signature",
      value: "Value",
      courier_status: "Courier status",
      status_description: "Status description",
      notes: "Notes",
      additional_info: "Additional inf",
      external: "External",
      transactionType: "Transaction type",
      transactionStatus: "Transaction status",
      customVariables: "Custom variables",
      systemVariables: "System Variables",
      newVariable: "New variable",
      affbay_lead_id: "Affbay lead ID",
      amount: "Amount (USD)",
      optional_description: "Optional description",
      title: "Title",
      is_home_page: "Visible on dashboard",
      is_home_page_short: "On dashboard",
      news_type: "News type",
      visible_on_home_page: "Visible on dashboard",
      date_from: "Start form",
      date_from_short: "Start",
      date_to: "End form",
      date_to_short: "End",
      time: "Time",
      hour: "Hour",
      day: "Day",
      week: "Week",
      month: "Month",
      year: "Year",
      today: "Today",
      lastWeek: "Last week",
      lastMonth: "Last month",
      acceptedBalance: "Accepted Balance",
      acceptedBalanceExplanation: "(Sum of filtered records)",
      actualBalance: "Actual Balance",
      requestPayout: "Request Payout",
      notAcceptedBalance: "Not Accepted (Pending) Transactions:",
      earningsChart: "Earnings Chart",
      generatedOn: "Generated on",
      groupBy: "Group by",
      codeName: "Code name",
    },
    informations: {
      active: "Active",
      inactive: "Inactive",
      online: "Online",
      offline: "Offline",
      viewPassword: "View password",
      notAvailable: "n/a",
      qualified: "Qualified",
      notQualified: "Not qualified",
      sessionExpired: "Session expired",
      visible: "Visible",
      invisible: "Invisible",
    },
    modalButtonsTitle: {
      edit: "Edit",
      createNewInstance: "Add",
      createNewClassifier: "Add",
      addNewPage: "Add page",
      addNewCompany: "Add company",
      addNewPartner: "Add new Partner",
      addNewUser: "Add user",
      editUser: "Edit users",
      addNewRole: "Add new role",
      editRole: "Edit role",
      addNewCountry: "Add new country",
      addNewCity: "Add new city",
      addNewCourier: "Add new courier",
      addNewStatus: "Add new status",
      addNewLocation: "Add new location",
      addNewPostCode: "Add new post code",
      addNewMethod: "Add new method",
      addNewProvince: "Add new province",
      addNewChannel: "Add new channel",
      addNewSubdistrict: "Add new subdistrict",
      addNewCategory: "Add new category",
      addNewCampaign: "Add new campaign",
      editCampaign: "Edit campaign",
      addNewLeadsRedirection: "Add",
      addNewSystemVariable: "Add new variable",
      addNewTrafficSource: "Add new variable",
      addNewCouriersApiMapping: "Add new mapping",
      addNewCouriersStatuses: "Add new status",
      addNewSystemClassifier: "Add",
      deposit: "Deposit",
      withdraw: "Withdraw",
      addNew: "Add new",
    },
    modalTitle: {
      edit: "Edit",
      editPage: "Edit page",
      addNewCompany: "Add company",
      addNewUser: "Add new user",
      editUser: "Edit users",
      addNewRole: "Add new role",
      editRole: "Edit role",
      addNewCountry: "Add new country",
      editCountry: "Edit country",
      addNewCity: "Add new city",
      addNewCourier: "Add new courier",
      editCourier: "Edit courier",
      addNewDistrict: "Add new district",
      addNewStatus: "Add new status",
      editStatus: "Edit status",
      addNewLocation: "Add new location",
      editLocation: "Edit location",
      addNewPostCode: "Add new post code",
      editPostCode: "Edit post code",
      addNewMethod: "Add new method",
      editPaymentMethod: "Edit payment method",
      addNewCategory: "Add new category",
      editCategory: "Edit category",
      addNewChannel: "Add new channel",
      editChannel: "Edit channel",
      addNewSubdistrict: "Add new subdistrict",
      editSubdistrict: "Edit district",
      addNewProvince: "Add new province",
      deleteProvince: "Delete province",
      editCampaign: "Edit campaign",
      editLeadsRedirection: "Edit leads redirection",
      addNewSystemVariable: "Add new system variable",
      addNewTrafficSource: "Add new traffic source",
      addNewCouriersApiMapping: "Add new couriers api mapping",
      addNewCouriersStatuses: "Add new couriers status",
      editCouriersStatus: "Edit couriers status",
      editCouriersApiMapping: "Edit couriers api mapping",
      addNewSystemClassifier: "Add new system classifier",
      editSystemClassifier: "Edit system classifier",
      editTrafficSource: "Edit traffic source",
      deposit: "Deposit",
      withdraw: "Withdraw",
      transaction: "Transaction",
      addNew: "Add new",
      edit_news: "Edit news",
      sendFeedback: "Send feedback",
    },
    modalTooltips: {
      see_company_members: "See company members",
      addNewUser: "Add new user",
      addNewRole: "Add new role",
      addNewCountry: "Add new country",
      editCountry: "Edit country",
      addNewCity: "Add new city",
      editCity: "Edit city",
      addNewCourier: "Add new courier",
      editCourier: "Edit courier",
      addNewStatus: "Add new status",
      editStatus: "Edit status",
      addNewLocation: "Add new location",
      editLocation: "Edit location",
      addNewPostCode: "Add new post code",
      editPostCode: "Edit post code",
      addNewMethod: "Add new method",
      editCategory: "Edit category",
      addNewChannel: "Add new channel",
      editChannel: "Edit channel",
      addNewSubdistrict: "Add new subdistrict",
      editSubdistrict: "Edit district",
      addNewProvince: "Add new province",
      deleteProvince: "Delete province",
      addNewCategory: "Add new category",
      addNewSystemVariable: "Add new system variable",
      addNewTrafficSource: "Add new traffic source",
      editTrafficSource: "Edit traffic source",
      addNewCouriersApiMapping: "Add new couriers api mapping",
      addNewCouriersStatuses: "Add new couriers status",
      editCouriersApiMapping: "Edit couriers api mapping",
      editCouriersStatus: "Edit couriers statuse",
      addNewSystemClassifier: "Add new system classifier",
      editSystemClassifier: "Edit system classifier",
      filters: "Filters",
      deposit: "Deposit",
      withdraw: "Withdraw",
    },
    tables: {
      campainFocusTable: "CampainFocusTable",
    },
    title: {
      edit_account_manager: "Edit account manager",
      createNewInstance: "Create new instance",
      createNewClassifier: "Create new classifier",
      editClassifier: "Edit Classifier",
      editPartner: "Edit Partner",
      editEmployee: "Edit Employee",
      editCompany: "Edit company",
      addNewPartner: "Add new Partner",
      addNewEmployee: "Add new Employee",
      addNewCompany: "Add new company",
      selectInstance: "Select instance",
      selectInstanceAndCampaign: "Select instance and campaign",
      campaignsAllInstances: "Campaigns in all instances",
      all: "All",
      allArrowAll: "All -> All",
      allArrow: "All ->",
      arrowAll: "-> All",
      createNewProduct: "create new product",
      campaignsActivityStatus: `Change classifier activity status`,
      // partnerActivityStatus: `Change Partner activity status`,
      // employeesActivityStatus: `Change Employee activity status`,
      userActivityStatus: `Change users activity status`,
      filters: "Filters",
      activityStatus: `Change activity status`,
      addNewPage: "Add new page",
      addNewCampaign: "Add new campaign",
      addNewLeadsRedirection: "Add new leads redirection",
      notFoundLabel: "Sorry, the page you visited does not exist.",
      addNew: "Add new",
      new: "New",
      change: "Change",
      inProgressTitle: "Page in progress",
      inProgressDesc: "Sorry, the page you visited is still in progress.",
    },
    columnTitle: {
      product_sku: "Product SKU",
      product_sku_short: "SKU",
      comment: "Comment",
      classifier_end: "Classifier end",
      phone_origin: "Phone (origin)",
      id: "ID",
      type: "Type",
      country: "Country",
      country_short: "Co.",
      name: "Name",
      second_name: "Second name",
      short_name: "Short name",
      campaign_name: "Campaign name",
      domain: "Domain",
      user: "User",
      password: "Password",
      password_short: "Pass",
      url: "Base campain URL",
      url_short: "URL",
      active: "Actual campaign activity status",
      active_short: "Active",
      // classifier_is_qualified: 'Actual classifier qualified status',
      // classifier_is_qualified_short: 'Qualified',
      online: "Actual campaign online status",
      online_short: "Online",
      created: "Date created",
      created_short: "Created",
      updated: "Recently Updated",
      updated_short: "Updated",
      options: "Options",
      instance: "Instance",
      focus_instance: "Focus instance",
      classifiers_external: "Classifiers external",
      affbay_status: "Affbay status",
      status: "Status",
      qualified: "Qualified",
      approvedAt: "Approved",
      approvedAt_short: "Date approved at",
      classifier: "Classifier",
      product: "Product",
      partner: "Partner",
      payout: "Payout",
      payout_scheme: "Payout Schema",
      payout_scheme_short: "Scheme",
      phone: "Phone",
      click_id: "click_id",
      pub_id: "Pub ID",
      lp_url: "Lp URL",
      role: "Role",
      description: "Description",
      application_access: "Application access",
      avalable_in_affbay: "Available in Affbay",
      avalable_in_affbay_short: "In Affbay",
      avalable_for_sale: "Available for sale",
      avalable_for_sale_short: "For sale",
      base_price: "Base prices",
      base_price_short: "Price",
      visible_for_partners: "Visible for partners",
      visible_for_partners_short: "For partners",
      categories: "Categories",
      landing: "Landing",
      preview: "Preview",
      is_visible: "Visible",
      is_top: "Top",
      is_mobile: "Mobile",
      is_desktop: "Desktop",
      deleted_at: "Deleted",
      accountCounter: "Account counter",
      isInternal: "Internal",
      partnerToken: "Partner token",
      email: "Email",
      manager: "Manager",
      company: "Company",
      skype: "Skype",
      telegram: "Telegram",
      is_active_short: "Active",
      key: "Key",
      focusId: "Focus ID",
      is_operative: "Operative",
      address: "Address",
      code: "Code",
      currency: "Currency",
      is_active: "Active",
      name_eng: "Name (eng)",
      bank_transfer: "Bank transfer",
      city: "City",
      province: "Province",
      district: "District",
      subdistrict: "Subdistrict",
      postCode: "Post code",
      courier: "Courier",
      lead_time: "Lead time",
      lead_time_text: "Lead time text",
      district_code: "District code",
      island: "Island",
      deliver_cost: "Deliver cost",
      resturn_cost: "Return cost",
      insurance_fee: "Insurance fee",
      code_fee: "Code fee",
      admin_fee: "Admin fee",
      dalivery_cost: "Delivery cost",
      permission_id: "Permission ID",
      is_qualified: "Qualified",
      is_contacted: "Contacted",
      is_qualified_short: "Qualified",
      value: "Value",
      courier_name: "Courier name",
      courier_name_short: "Courier",
      courier_status: "Courier status",
      status_description: "Status description",
      notes: "Notes",
      external: "External",
      additional_inf: "Additional informations",
      additional_inf_short: "Informations",
      view: "View",
      is_manual_short: "Manual",
      is_manual: "Is Manual",
      is_successful_short: "Successful",
      is_successful: "Is Successful",
      response_time_short: "Time",
      response_time: "Response time",
      response_status_short: "Status",
      response_status: "Response Status",
      response_text_short: "Text",
      response_text: "Response Text",
      title: "Title",
      is_home_page: "Visible on dashboard",
      is_home_page_short: "On dashboard",
      news_type: "News type",
      visible_on_home_page: "Visible on dashboard",
      date_from: "Start form",
      date_from_short: "Start",
      date_to: "End form",
      date_to_short: "End",
      scheme: "Scheme",
      leadId: "Lead ID",
      accepted: "Accepted",
      date: "Date",
      params: "Params",
      exclude_from_double: "Exclude from double",
      exclude_from_double_short: "Double Excl.",
    },
    tooltips: {
      see_company_members: "See company members RU",
      hide_postbacks: "Hide postbacks",
      show_postbacks: "Show postbacks",
      copyKey: "Copy key",
      editPage: "Edit page",
      deletePage: "Delete page",
      cantDeleteAdminSuperAdmin: `Can't delete Admin/Super admin`,
      userActivityStatus: "Change users activity status",
      deleteUser: "Delete user",
      changeRoleActiveStatus: "Change roles sctive status",
      editRole: "Edit role",
      deleteRole: "Delete role",
      changeActiveStatus: "Change active status",
      viewCampaign: "View campaign",
      viewCompany: "View company",
      changeQualifiedStatus: "Change qualified status",
      deleteStatus: "Delete status",
      deletePostCode: "Delete post code",
      deleteCstegory: "Delete category",
      change_activity_status: "Change activity status",
      delete_leads_direction: "Delete direction",
      edit_leads_direction: "Edit leads redirection",
      delete: "Delete",
      edit: "Edit",
      accept: "Accept",
      reject: "Reject",
    },
    statement: {
      unrecognized_error: "Unrecognized error",
      refresh_page: "Refresh page",
      your_account_has_been_created: "Your account has been activated",
      your_account_has_been_created_message:
        "Now you need to wait for our specialist to accept your account. ",
      sorry_we_could_not_get_your_data: `Sorry, we couldn't get your data`,
      databaseConnectionError:
        "Something went wrong while connecting to the database!",
      databaseConnectionSuccess: "Udane pobranie z bazy danych!",
      instanceCreated: "Udane dodanie instancji!",
      classifierCreated: "Classifier created!",
      classifierUpdated: "Classifier updated!",
      thePropsAreTheSame:
        "Wartości w polach nie uległy zmianie - do ustalenia co z tym robimy",
      campaignUpdated: "Udana aktualizacja kampanii!",
      qulifiedUpdate: "Udana zmiana kwalifikacji",
      app_user_updated: "App user updated!",
      app_user_created: "App user created!",
      status_updated: "Status updated!",
      there_was_a_problem_removing_app_user:
        "There was a problem removing app user",
      app_user_deleted: "App user deleted",
      there_was_a_problem_removing_role: "There was a problem removing role",
      there_was_a_problem_removing_permission:
        "There was a problem removing permission",
      there_was_a_problem_creating_role: "There was a problem creating role",
      user_role_updated: "User role updated!",
      companie_created: "Companie created!",
      companie_updated: "Companie updated!",
      there_was_a_problem_removing_city: "There was a problem removing city",
      city_deleted: "City deleted!",
      city_created: "City created!",
      city_updated: "City updated!",
      there_was_a_problem_removing_country:
        "There was a problem removing country",
      country_created: "Country deleted!",
      country_updated: "Companie updated!",
      there_was_a_problem_removing_courier:
        "There was a problem removing courier",
      courier_deleted: "Courier deleted!",
      courier_updated: "Courier updated!",
      courier_created: "Courier created!",
      there_was_a_problem_removing_district:
        "There was a problem removing district",
      district_deleted: "District deleted!",
      district_updated: "District updated!",
      district_created: "Dictrict created!",
      there_was_a_problem_removing_location:
        "There was a problem removing location",
      location_deleted: "Location deleted!",
      location_updated: "Location updated!",
      location_created: "Location created!",
      there_was_a_problem_removing_payment_method:
        "There was a problem removing payment method",
      payment_method_deleted: "Payment method deleted!",
      payment_method_updated: "Payment method updated!",
      payment_method_created: "Payment method created!",
      there_was_a_problem_removing_post_code:
        "There was a problem removing post code",
      post_code_deleted: "Post code deleted!",
      post_code_updated: "Post code updated!",
      post_code_created: "Post code created!",
      there_was_a_problem_removing_product_category:
        "There was a problem removing product category",
      product_category_deleted: "Product category deleted!",
      product_category_updated: "Product category updated!",
      product_category_created: "Product category created!",
      there_was_a_problem_removing_province:
        "There was a problem removing province",
      province_deleted: "Province deleted!",
      province_updated: "Province updated!",
      province_created: "Province created!",
      there_was_a_problem_removing_sales_channel:
        "There was a problem removing sales channel",
      sales_channel_deleted: "Sales channel deleted!",
      sales_channel_updated: "Sales channel updated!",
      sales_channel_created: "Sales channel created!",
      there_was_a_problem_removing_status:
        "There was a problem removing status",
      status_deleted: "Status deleted!",
      status_created: "Status created!",
      there_was_a_problem_removing_subdistrict:
        "There was a problem removing subdistrict",
      subdistrict_deleted: "Subdistrict deleted!",
      subdistrict_updated: "Subdistrict updated!",
      subdistrict_created: "Subdistrict created!",
      employee_updated: "Employee updated!",
      employee_created: "Employee created!",
      filters_confirmed: "Filtry potwierdzone",
      filters_have_been_reset: "filters have been reset",
      there_was_a_problem_removing_page: "There was a problem removing page",
      page_deleted: "Page deleted!",
      page_updated: "Page updated!",
      page_created: "Page created!",
      partner_updated: "partner updated!",
      partner_created: "partner created!",
      change_permission_status: "Change permissions status",
      there_was_a_problem_removing_campaign:
        "There was a problem removing campaign",
      there_was_a_problem_creating_campaign:
        "There was a problem creating campaign",
      campaign_deleted: "Campaign deleted!",
      campaign_updated: "Campaign updated!",
      campaign_created: "Campaign created!",
      role_deleted: "Role deleted!",
      choose_out_of_scope: "Choose out of the scope!",
      date_changed: "Date changed",
      session_expired: "Session expired",
      session_expired_description:
        "Your session has expired. Please login again to continue working",
      incorrect_login_information:
        "Your login information was incorrect. Please check and try again",
      there_was_a_problem_removing_system_variable:
        "There was a problem removing system variable",
      system_variable_deleted: "System variable deleted!",
      system_variable_updated: "System variable updated!",
      system_variable_created: "System variable created!",

      traffic_source_deleted: "Traffic source deleted!",
      traffic_source_updated: "Traffic source updated!",
      traffic_source_created: "Traffic source created!",

      there_was_a_problem_removing_couriers_api_mapping:
        "There was a problem removing couriers api mapping",
      couriers_api_mapping_deleted: "Couriers api mapping deleted!",
      couriers_api_mapping_updated: "Couriers api mapping updated!",
      couriers_api_mapping_created: "Couriers api mapping created!",
      system_classifier_created: "System classifierl created!",
      system_classifier_updated: "System classifierl updated!",
      updated: "Updated!",
      created: "Created!",
      deleted: "Deleted!",
      success: "Success!",
      accepted: "Accepted!",
      rejected: "Rejected",
      noCompanyIdAssigned:
        "Your account has no company assigned. Please contact us through support panel.",
      noDataToDisplay: "Sorry, there is no data to display",
    },
    tabs: {
      createNewProduct: "create new product",
      pricing: "Pricing",
      conversion: "Conversion",
      affbayVisibility: "Affbay visibility",
      landings: "Landings / Prelandings",
    },
    navigationItems: {
      dashboard: "Dashboard",
      statistics: "Statistics",
      financeStatistics: "Finance statistics",
      leads: "Leads",
      employees: "Employees",
      products: "Products",
      pages: "Pages",
      landings: "Landings",
      companies: "Companies",
      partners: "Partners",
      campaigns_partner: "Campaigns",
      campaigns_admin: "Campaigns admin",
      finance: "Finance",
      financeAdmin: "Finance admin",
      tools: "Tools",
      news: "News",
      news_admin: "News admin",
      support: "Support",
      faq: "FAQ",
      contact: "Contact",
      login: "Login",
      register: "Register",
      offers: "Offers",
      create_new_order: "Create new order",
      orders: "Orders",
      couriersStatusMapping: "Status mapping",
      account_managers: "Account Managers",
      settings: "Settings",
    },
    subNavigationItem: {
      k8_services: "K8 Services",
      sales: "Sales",
      sales_p2: "P2",
      sales_resale: "P3",
      sales_winback: "Winback",
      administration: "Administration",
      appRemarketing: "Remarketing - P2",
      appUsers: "Users",
      appUsersRole: "Users role",
      webSocket: "WebSocket",
      appUsersPermissions: "Users permissions",
      // Call center
      callCenter: "Call center",
      cc_instances: "Instances",
      cc_campaigns: "Campaigns",
      cc_classifiers: "Classifiers",
      li_classifiers_cc: "Mapping classifiers",
      cc_webhooks: "Webhooks",
      cc_leads_direction: "Leads redirection",
      // Settings
      setting: "Setting",
      api: "API",
      payment: "Payment",
      postbacks: "Postbacks",
      referralHistory: "Referral history",
      security: "Security",
      settings: "Settings",
      // Dictionaries
      dictionaries: "Dictionaries",
      countries: "Countries",
      cities: "Cities",
      couriers: "Couriers",
      districts: "Districts",
      subdistricts: "Subdistricts",
      statuses: "Statuses",
      locations: "Locations",
      postCodes: "Post cods",
      paymentMethods: "Payment methods",
      productCategories: "Product cat.",
      salesChannels: "Sales channels",
      provinces: "Provinces",
      system_variables: "System variables",
      traffic_sources: "Traffic sources",
      couriers_api_mapping: "Couriers mapping",
      couriers_statuses: "Couriers statuses",
      system_classifiers: "System classifiers",
    },
    others: {
      of: "of",
      rows: "rows",
    },
    contentTitle: {
      support: "Support",
    },
    contentText: {
      alreadyHaveAnAccount: "Already have an account?",
      iDontHaveAnAccount: "I don't have an account.",
      rememberThePassword: "Remember the password?",
      support:
        "If you need help with your account, have questions about using the platform or have technical problems, write to us.",
    },
    links: {
      all_news: "All news",
      login: "Login",
      registration: "Registration",
      forgotYourPassword: "Forgot your password?",
    },
    placeholders: {
      enterName: "Enter name",
      enterEmail: "Enter e-mail",
      enterPassword: "Enter password",
      repeatPassword: "Repeat password",
      selectCountry: "Select country",
      notRequired: "Not required",
      select: "Select...",
      globalSearch: "Global search",
      search: "Search",
      findProduct: "Find product",
      choose_affbay_status: "Choose affbay status",
      choose_country: "Choose country",
      choose_company: "Choose company",
      choose_product: "Choose product",
      choose_partner: "Choose partner",
      choose_scheme: "Choose scheme",
      choose_classifier: "Choose classifier",
      choose_remarketing_campaign: "Choose campaign",
      enter_min_4_letters: "Entere min 4 letters",
    },
    pageTitle: {
      k8_services: "K8 Services",
      dashboard: "Dashboard",
      account_managers: "Account managers",
      loginContent: "Log In",
      registerContent: "Registration",
      resetPasswordContent: "Restore password",
      employees: "All employees PL + All employees Call center",
      products: "Products",
      pages: "Pages",
      addNewCompany: "Add company",
      companies: "Companies",
      partners: "Partners",
      administrationUsers: "Administration - Users",
      administrationUsersRole: "Administration - Users Role",
      instances: "Instances",
      instancesForCCSimplified: "Call center API connection",
      campaigns: "Campaigns",
      classifiers: "Classifiers",
      mappingClassifiers: "Mapping classifiers",
      webhooks: "Webhooks",
      countriesDictionary: "Countries dictionary",
      citiesDictionary: "Cities dictionary",
      couriersDictionary: "Couriers dictionary",
      districtsDictionary: "Districts dictionary",
      statusesDictionary: "Statuses dictionary",
      locationsDictionary: "Locations dictionary",
      postCodeDictionary: "Post codes dictionary",
      paymentMethodDictionary: "Payment methods dictionary",
      productCategoriesDictionary: "Product categories dictionary",
      salesChannels: "Sales channels dictionary",
      subdistrictsDictionary: "Subdistricts dictionary",
      provincesDictionary: "Provinces dictionary",
      campaigns_admin: "Campaigns - Admin",
      orders: "Orders",
      leads_directions: "Leads redirections",
      system_variables: "System variables",
      traffic_sources: "Traffic sources",
      couriers_api_mapping: "Couriers api mapping",
      system_classifiers: "System classifiers",
      finance: "Finance",
      news_admin: "News Admin",
      finance_admin_view: "Finance admin view",
      resaleP3: "P3 Resale",
      resaleP2: "P2 Resale",
    },
    productsFormMain: {
      editProduct: "Edit product",
      createNewProduct: "Create new product",
      englishName: "English name",
      currency: "Currency",
      callCenter: "Call Center",
      sku: "SKU",
      originalName: "Original name",
      shortName: "Short name",
      printName: "Print name",
      categories: "Categories",
      availableInAffbay: "Available in AFFBAY",
      testProduct: "Test product",
      availableForSale: "Available for sale",
      active: "Active",
      country: "Country",
      sales: "Sales",
      mainCamapignId: "Main campaign [id]",
      resellCampaignId: "Resell campaign [id]",
      productionCost: "Production cost (USD)",
      productionCostLocalCurrency: "Production cost (local currency)",
      affbayVisibility: "Affaby Visibility",
      visibleForPartners: "Visible for partners",
      visibleOnHomePage: "Visible on home page (top offers)",
      isRestricted: "Is restricted",
      defaultGlobalPayout: "Default global payout (USD)",
      defaultPayoutScheme: "Default payout scheme",
      cpa: "CPA",
      cpl: "CPL",
      landingPriceForConsumer: "Landing price for consumer",
      approveVisibleOnPage: "Approve visible on page (%)",
      payoutVisibleOnPage: "Payout visible (string) on page (USD)",
      descriptionEN: "Description EN",
      descriptionRU: "Description RU",
      traffic_sources_en: "Traffic sources (description) EN",
      traffic_sources_ru: "Traffic sources (description) RU",
      terms_en: "Terms EN",
      terms_ru: "Terms RU",
      conditions_en: "Conditions EN",
      conditions_ru: "Conditions RU",
      productsWillNotBeAvailableInAffbay:
        "Products will not be available in AFFBAY",
      updateCampaigns: "Refresh campaings list",
      save: "Save",
      create: "Create",
      reset: "Reset",
    },
    checkBoxes: {
      iWantToReceiveImportantNewsByEmail:
        "I want to receive important news by email (recommended)",
      iWantToReceivePromotionslNewsByEmail:
        "I want to receive promotional news by email",
      iWantToReceiveInformationAboutChangesInOffersByEmail:
        "I want to receive information about changes in offers by email",
      iWantToReceiveOffersOfOffersByEmail:
        "I want to receive offers of offers by email",
      add_to_affbay_classifiers_library: "Add to Affbay Classifiers library",
    },
    landingPage: {
      about: "About",
      topOffers: "Top offers",
      contact: "Contact",
      logIn: "Log In",
      certifiedProducts: "CERTIFIED PRODUCTS FROM DIRECT ADVERTISER",
      afiliateNetwork: "Affiliate Network in Thailand",
      saudiArabAndIndonesia: "Saudi Arabia and Indonesia",
      becomeAPartner: "Become a partner",
      activePartners: "Active partners",
      offersInAsia: "Offers in Asia",
      yearsOnTheMarket: "Years on the market",
      countries: "Countries",
      aboutText:
        "Affbay is a direct advertiser in the markets of Asia and the Arab countries. We do not resell the offers, all products are our own. Here you will find dozens of offers on the topic of beauty, health and weight loss with payments from $ 18 higher for a confirmed application. We work on models CPA, CPL, CPI.",
      regularPayouts: "Regular Payouts",
      regularPayoutsText: "Payouts without delay on most payment systems.",
      highPayouts: "High Payouts",
      highPayoutsText: "From $ 18 and above for a confirmed application.",
      ownCallCenter: "Own call center",
      ownCallCenterText:
        "Experienced CC specialists performing work efficiently.",
      personalManager: "Personal manager",
      personalManagerText:
        "Responsive support will help you solve any problems.",
      weDontHaveTopOffers: "We don't have any top offers right now.",
      howCanWeHelpYou: "How can we help you?",
      writeUs: "Write us about your concerns.",
      becomeOurPartner: "Become our partner and start earning right now",
      affbayAsia: " Affbay.asia",
      ownedBy: "Owned By: Izabella Frolushkin FZE",
      privacyPolicy: "Privacy Policy",
      termsAndConditions: "Terms and Conditions",
      send: "Send",
      name: "Name",
      email: "E-mail",
      object: "Object",
      message: "Message",
      thankYou: "Thank you",
      youWillBeHearing:
        "Your message was sent. We will handle your issue with 48 hours.",
      startEarning: "Start earning",
      sitePrice: "Site price",
      approve: "Approve",
      payouts: "Payouts",
      country: "Country",
    },
    loginPage: {
      logIn: "Log In",
      registration: "Registration",
      email: "E-mail",
      password: "Password",
      forgotYourPassword: "Forgot your password?",
      logIntoThePlatform: "Log into the platform",
      iDontHaveAnAccount: "I don't have an account.",
      send_request_again: "Send request again",
    },
    registrationPage: {
      registration: "Registration",
      name: "Name",
      email: "E-mail",
      password: "Password",
      repeatPassword: "Repeat password",
      country: "Country",
      skype: "Skype",
      telegram: "Telegram",
      notRequired: "Not required",
      iAgreeToAffbayAsia: "I agree to Affbay Asia",
      privacyPolicy: "Privacy Policy",
      and: "and",
      termsAndConditions: "Terms and Conditions",
      nextStep: "Next Step",
      alreadyHaveAnAcount: "Already have an account?",
      logIn: "Log in",
      thxForReg: "Thank you for registration",
      toStartWork: "To start work please provide us few information",
      surveyIsNotAvailable: "Survey is not available",
      thisAccountIsA: "This account is a...",
      whichgeos: "Which geos you have worked with before?",
      whatgeo: "What geos do you want to work with?",
      whatTypeOfTraffic: "What type of traffic are you using?",
      whatBugdets: "What budgets did you work with?",
      whatDoYouPrefer: "What do you prefer - S2S or API?",
      submitMyApplication: "Submit my application",
      enterYourName: "Enter your name",
      enterEmail: "Enter e-mail",
      enterPassword: "Enter password",
    },
    submitSuccess: {
      congrats: "Congratulations, your account has been created!",
      nowYouCan: "Now you can login to your account",
      logIn: "Log In",
    },
    resetPassword: {
      password_changed: "Your password has been changed. You can login now.",
      typeTheAdress:
        "Type the address linked to your account and we'll send you password reset instructions. Also, check your spam folder.",
      email: "E-mail",
      set_new_password: "Set new password",
      remember: "Remember your password?",
      logIn: "Log In",
      checkEmail: "Check your e-mail",
      withinAFew:
        "Within a few minutes you will receive an email. In this message we will send you a link to set new password.",
      new_password: "New password",
    },
    supportView: {
      support: "Support",
      ifYouNeedHelp:
        "If you need help with your account, have questions about using the platform or have technical problems, write to us.",
      subject: "Subject",
      message: "Message",
      send: "Send",
    },
    newsView: {
      news: "News",
      allNews: "All news",
    },
    offersView: {
      offers: "Offers",
      offersFound: "offers found",
      show: "Show:",
      sortBy: "Sort by:",
      category: "Category",
      conversionType: "Conversion type",
      sort: "Sort",
      accessRequest: "Access Request",
      wirteUs:
        "Write us about traffic sources, with whom you work and your experience.",
      send: "Send",
      thankYou: "Thank you",
      youWillBeHearing: "You will be hearing from us soon!",
      sitePrice: "Site price",
      payouts: "Payouts",
      country: "Country",
      originalCountry: "Original country",
      readMore: "Read more",
      exampleCodePhp: "Download example",
    },
    offersDetails: {
      sku: "SKU",
      // tabs
      description: "Description",
      targetURL: "Target URL",
      postbacks: "Postbacks",
      trafficSources: "Traffic sources",
      news: "News",
      terms_and_conditions: "Terms & conditions",
      landings_tab: "Landings",
      // product card
      payout: "Payout",
      approve: "Approve",
      sitePrice: "Site price",
      category: "Category",
      country: "Country",
      devices: "Devices",
      // target url
      step_1:
        "Step 1. Choose landing page and prelanding. You can choose multiple items which be opened randomly in your campaign",
      step_2:
        "Step 2. Enter new name for your campaign and click Generere URL button to save it.",
      landings: "Landings",
      prelandings: "Prelandings",
      campaign_name: "Campaign name",
      generateURL: "Generate URL",
      allProductCampaigns: "All product campaigns",
      name: "Name",
      prelanding: "Prelanding",
      landing: "Landing",
      URL: "URL",
      youHaveTo: "You have to choose LP and type Campaign name",
      noLandings: "No pages to display",
      // postbacks
      urlForSales: "Postback URL for Sales (means APPROVE for CPL)",
      urlForHolds: "Postback URL for Holds (means APPROVE for CPL)",
      urlForRejects: "Postback URL for Rejects (means APPROVE for CPL)",
      urlForTrash: "Postback URL for Trash (means REJECTED for CPL)",
      urlForDuplicated: "Postback URL for Duplicated (means REJECTED for CPL)",
      urlForExcluded: "Postback URL for Excluded (means REJECTED for CPL)",
      urlForValidated: "Postback URL for Validated",
      googleTrackingID: "Google tracking ID",
      facebookPixelID: "Facebook pixel ID",
      customPixelID: "Custom pixel ID",
      useGlobalPostbacks: "Use global postbacks",
      createPostback: "Create postback",
      updatePostback: "Update postback",
      fillInTheData: "Fill in the data below to create a new postback",
      protocol: "Protocol",
      domain: "Domain",
      exampleCOM: "example.com",
      extraPathParameter: "Extra path parameter",
      folderSlash: "/folder/subfolder",
      parameter: "Parameter",
      addNew: "Add new",
      postbackURLPreview: "Postback URL Preview",
      save: "Save",
      basic: "Basic",
      advanced: "Advanced",
      postbackSavedSuccessfully: "Postback saved successfully",
      postbackSavedWithWarning: "Postback saved with warning",
      postbackNotSaved: "Unable to saved postback",
      clearPostback: "Clear Postback",
    },
    profileView: {
      personlaData: "Personal data",
      changePassword: "Change password",
      notifications: "Notofications",
      apitoken: "API Token",
      // personal data
      email: "E-mail",
      name: "Name",
      phone: "Phone number",
      telegram: "Telegram",
      skype: "Skype",
      timeZone: "Time zone",
      apply: "Apply",
      cancel: "Cancel",
      id_referral: "Registration link",
      // change password
      currentPass: "Current password",
      newPass: "New password",
      repeatNewPass: "Repeat new password",
      change: "Change",
      // notifications
      importantNews: "I want to receive important news by email (recommended)",
      promotionalNews: "I want to receive promotional news by emial",
      information:
        "I want to receive information about changes in offers by email",
      offers: "I want to receive offers of offers by email",
      customVariablesList: "Custom Variables List",
      postbacksList: "Postbacks List",
      // api token
    },
    toolsView: {
      open: "Open",
      tools: "Tools",
      // faq
      faq: "FAQ",
      answersToFAQ:
        "Answers to frequently asked questions regarding payments for their implementation and Affbay in general",
      readMore: "Read more",
      // API Documentation
      apiDocs: "API Documentation",
      leadSendExample: "Send lead example form",
      readOurDocs:
        "Read our documentation to get answers to many questions regarding the API.",
      readDocs: "Read documentation",
      // postback documentation
      postbackDocs: "Postback Documentation",
      postbacksNotify:
        "Read our documentation to get answers to many questions regarding the API.",
      // integration archi model
      integrationArchiModel: "Integration Architecture Model",
      hardToUnderstand:
        "Hard to understand how Affbay works? Read more about a few examples of our architecture.",
      // number validation
      numberValidation: "Number validation",
      checkingTheNumber:
        "Checking the number is the key to improving the quality of customer acquisition and revenue.",
      // longpage widget
      longpageFromWidget: "Longpage form widget",
      longpageFromWidgetDescription:
        "Include our form into your webpage. Simply paste our code, add your token and sku to start generating new leads.",
    },
    select: {
      select_timezone: "Select timezone",
      select_status: "Select status",
      select_available: "Select available",
      select: "Select",
      select_country: "Select country",
    },
    spin: {
      loading: "Loading...",
    },
    faq: {
      header_1: "What is Affbay Asia?",
      header_2: "What kinds of integration do we support?",
      header_3: "Do you need your own tracker to integrate by S2S?",
      header_4: "Which parameters are supported by Affbay (S2S integration)?",
      header_5: "How to add parameters you want to receive?",
      header_6: "Which parameter is Affbay’s?",
      header_7: "How to configure general postbacks for S2S?",
      header_8: "What is postback for sales, holds, rejected?",
      header_9: "How to configure getting postback for concrete offer?",
      header_10: "How to add payout to postback url?",
      header_11: "How should a postback url look like?",
      content_1:
        "We’re direct advertisers in Indonesia and Thailand, we have our own high conversion COD offers. More geos in Asia coming soon!",
      content_2:
        "We can integrate with you by S2S or by API. Documentation for API is provided on our website in section Offers (at the top of webpage). Choose offers you like and click Details.",
      content_3:
        "Affbay is a user friendly platform, which provides you with your personal account with dashboard and requires no tracker, although we can provide you S2S postback integration with your trackers.",
      content_4:
        'You can add any parameter that is supported by your tracker. See "How to add parameters you want to receive?".',
      content_5:
        "Enter your personal account -> click your User name -> Profile -> Global postbacks. Just add any parameter without brackets {} () and put it also in your postback url.",
      content_6: [
        "Our built in parameters are:",
        "click_id - global identifier (UUID) of a specific lead",
        "campaign_id - global identifier (UUID) of a specific campaign",
        "aff_id - global identifier (UUID) of a specific user (you).",
        "If you specify a custom variable named as one of the above, postback will use it, substituting your tracker data instead of our default data. We discourage this to avoid confusion.",
      ],
      content_7:
        "Enter your personal account -> click your User name -> Profile -> Global postbacks. Here you can add you general postback url. If you want to receive postbacks for sales – just put your postback url in this field, the same for postback for holds and rejected. Also you can add Facebook Pixel and Google Tracking ID for all the offers.",
      content_8:
        "If you want to receive postback for approved leads – choose postback for sales. Postback for holds means getting postback for every lead that came into our system. Postback for rejected means getting postback for leads that were rejected – invalid phone number or leads not being reached by Call Center in a month.",
      content_9:
        "Enter section Offers (at the top of webpage) -> choose offers and click Details -> there you can add postback urls and also Facebook Pixel and Google Tracking ID for specific offer.",
      content_10: "&amount=13&payout_type=pps¤cy=USD",
      content_11:
        "https://callbacks.bitterstrawberry.org/?token=0c4c1249825285a2997bacf09c09ec28&hash={hash}&transaction_id={click_id}&amount=13&payout_type=pps¤cy=USD",
    },
    news_type: {
      all_news: "All news",
      new: "New",
      important: "Important",
      info: "Info",
    },
    setting_page: {
      click_id: "Click ID",
      check_click_id: "Check click ID doubles",
      click_id_days: "Number of days to check",
    },
  },
  id_ID: {
    validation: {
      current_password_is_incorrect: "Current password is incorrect",
      // Name
      nameIsRequired: "Name is required",
      // Last name
      lastNameIsRequired: "Last name is required",
      // E-mail
      emailIsRequired: "E-mail is required",
      incorrectEmailAdres: "Incorrect e-mail adress",
      requiredUtf8Characters: "Required utf-8 characters",
      // Password
      passwordIsRequired: "Password is required",
      confirmPasswordIsRequired: "Confirm password is required",
      requiredTheSamePasswords: "Required the same passwords",
      passwordCondition:
        "Password must contain at least 1 upper case letter, 1 lower case letter, 1 numeric character, at least 2 special characters and must contain at least 8 characters and max 32 characters.",
      // SkypeId
      skypeIdIsRequired: "Skype ID is required",
      // Telegram
      telegramIsRequired: "Telegram is required",
      // Country
      countryIsRequired: "Country is required",
      // Rest
      requiredMin3Characters: "Required min 3 characters",
      requiredMin8Characters: "Required min 8 characters",
      requiredMax15Characters: "Required max 15 characters",
      requiredMax20Characters: "Required max 20 characters",
      requiredMax30Characters: "Required max 30 characters",
      requiredMax50Characters: "Required max 50 characters",
      requiredMax191Characters: "Required max 191 characters",
      requiredMax250Characters: "Required max 250 characters",
      requiredMax255Characters: "Required max 255 characters",
      requiredAlphaNumeric: "Required alphaNumeric",
      statusIsRequired: "Status is required (number)",
      requiredMax11Numbers: "Required max 11 numbers",
      required: "Field is required",
      invalidDomain: "Domain invalid",
      duplicatedParam: "Duplicated parameter name",
      invalidParamName: "Invalid parameter name",
      invalidParamValue: "Invalid parameter value",
      invalidUrl: "Invalid URL",
      customInvalidUrlError:
        "IMPORTANT: This URL seems invalid but you can still save it.",
    },
    statistics: {
      callCenterTitle: "Call center classifiers",
      totalDescription:
        "Validated, Send to Call Center, Awaiting, Rejected, Excluded and Sold",
      awaitingDescription:
        "Classification pending (called leads by Call Center)",
      rejectedDescription: "Called but rejected",
      soldDescription: "Verified sales",
      orderCreatedDescription: "Awaiting verification",
      trashDescription: "Wrong numbers etc.",
      excludedDescription: "Doubles and not contacted",
      validatedDescription:
        "Validated by system but not sent to call center yet",
      sendToCcDescription: "Sent to call center but not called yet",
      doubledDescription: "Marked by system",
      verifiedApprovrdRateDescription: "((Order Created + Sold) / Total) * 100",
      approvedRateDescription: "(Sold / Total) * 100",
      leads: "Leads",
      total: "Total",
      awaiting: "Awaiting",
      rejected: "Rejected",
      approved: "Approved",
      approveRate: "Approve rate",
      payoutAmount: "Payout amount",
      sold: "Sold",
      orderCreated: "Order Created",
      trash: "Trash",
      excluded: "Excluded",
      validated: "Validated",
      sendToCC: "Send to Call Center",
      sentcc: "Sent to CC",
      double: "Double",
      verifiedApprovrdRate: "Approve Rate (Potential)",
      approvedRate: "Verified Approve Rate",
      unrecognized: "Unrecognized",
      noMoney: "No money",
      noMoneyDescription: "No money Description",
      awaitingAll: "Awaiting all",
      awaitingAllDescription: "New lead, Validated, Awaiting, Send to CC",
      title: "Statistics leads",
      summary: "Summary statistics",
      numberOfLeadsByOffer: "Number of leads by offer",
      percentageOfApprovedLeads: "Percentage of approved leads",
    },
    tabPane: {
      details: "Details",
      summary: "Summary",
      personalData: "Personal data",
      changePassword: "Change password",
      notifications: "Notifications",
      apiToken: "API Token",
      globalPostbacks: "Global postbacks",
      registrationLink: "Registration link",
    },
    buttons: {
      upload: "Upload",
      yes: "Yes",
      no: "No",
      confirm: "Confirm",
      today: "Today",
      yesterday: "Yesterday",
      thisMonth: "This month",
      lastMonth: "Last month",
      last30Days: "Last 30 days",
      resetFilters: "Clear filters",
      addFilter: "Add filter",
      removeFilter: "Remove filter",
      clearAll: "Clear all",
      submit: "Submit",
      search: "Search",
      cancel: "Cancel",
      refreshTable: "Refresh table",
      updateCampaign: "Update campaign",
      reset: "Reset",
      register: "Register",
      create: "Create",
      login: "Login",
      save: "Save",
      save_new_password: "Save new password",
      send: "Send",
      apply: "Apply",
      generateToken: "Generate token",
      addNewEmployee: "Add new employee",
      addNewProduct: "Add new product",
      add_new: "Add new",
      filter: "Filter",
      addVariable: "Add variable",
      goToMainPage: "Go to main page",
      download: "Download",
      exportXlsx: "Export XLSX",
      downloading: "Downloading",
      new: "New",
      switchToUser: "Switch To",
      backToAdminAccount: "Back To Admin",
      setPostbackForAllProducts: "Set this postbacks for all products",
      show: "Show",
      hide: "Hide",
      enable: "Enable",
      disable: "Disable",
    },
    radioButtons: {
      leadsCreatedDate: "Lead created at date",
      leadsApprovedDate: "Lead approved at date",
      leadsSoldDate: "Lead sold at date",
      leadsCallCenterLastCallDate: "Lead call center last call date",
      salesCountryTimezone: "Sales country trimezone",
      myTimezone: "My timezone",
    },
    labels: {
      visibility: "Visibility",
      active_products: "Active products",
      inactive_products: "Inactive products",
      all_products: "All products",
      are_you_sure: "Are you sure?",
      do_you_want_to_generate_this_campaign:
        "Do you want to generate this campaign?",
      create_leads_on_order_delivered_status_global:
        "Create leads on order delivered status ( GLOBAL )",
      partner_token: "Partner token",
      warnings: "Warnings",
      fails: "Fails",
      success: "Successes",
      comment: "Comment",
      selected: "Selected",
      items: "items",
      global_actions: "Global actions",
      partners_that_registerd_from_your_ref_link:
        "Partners that registerd from your ref link: ",
      beauty: "Beauty",
      health: "Health",
      slimming: "Slimming",
      adult: "Adult",
      other: "Other",
      search: "Search",
      latest_news: "Latest News",
      top_offers: "Top offers",
      found: "Found",
      rows: "rows",
      balance: "Balance",
      hold: "Hold",
      your_manager: "Your manager",
      no_manager: "No manager",
      profile: "Profile",
      log_out: "Log Out",
      reportByTimezone: "Report by time zone",
      dateType: "Date type",
      dateRange: "Date range",
      dateFrom: "Date from",
      dateTo: "Date to",
      setQuickDate: "Set quick date",
      selectedFilters: "Selected filters",
      status: "Status",
      country: "Country",
      product: "Product",
      lead_type: "Lead type",
      partner: "Partner",
      userEmail: "User e-mail",
      payoutScheme: "Payout scheme",
      remarketingCampaign: "Remarketing campaign",
      classifierName: "Classifier name",
      accountManager: "Account manager",
      selectTimeZone: "Select time zone ",
      timezone: "Time zone",
      isActive: "Is active ",
      isExcluded: "Excluded from double",
      isOnline: "Is online ",
      type: "Type",
      name: "Name",
      domain: "Domain",
      user: "User",
      password: "Password",
      repeatPassword: "Repeat password",
      baseUrl: "Base URL",
      id: "ID",
      email: "E-mail",
      skype: "Skype",
      telegram: "Telegram",
      object: "Object",
      message: "Message",
      section: "Section",
      phone: "Phone number",
      currentPassword: "Current password",
      newPassword: "New password",
      repeatNewPassword: "Repeat new password",
      apiToken: "API Token",
      click_id: "click_id",
      pub_id: "Pub ID",
      lp_url: "Lp URL",
      second_name: "Second name",
      short_name: "Short name",
      role: "Role",
      description: "Description",
      application_access: "Application access",
      pages: "Pages",
      products: "Products",
      addressLine1: "Adress line 1",
      addressLine2: "Adress line 2",
      city: "City",
      state: "State",
      zipCode: "ZIP code",
      isInternal: "Internal",
      partnersAwaitingActivation: "Partners awaiting activation",
      allPartners: "All Partners",
      company: "Company",
      basedOnRole: "Based on role",
      campaigns: "Campaigns",
      instances: "Instances",
      currency: "Currency",
      code: "Code",
      address: "Address",
      operative: "Operative",
      name_eng: "Name (eng)",
      bank_transfer: "Bank transfer",
      district: "District",
      subdistrict: "Subdistrict",
      island: "Island",
      courier: "Courier",
      post_code: "Post code",
      destination_code: "Destination code",
      lead_time: "Lead time",
      lead_time_text: "Lead time text",
      province: "Province",
      delivery_cost: "Delivery cost",
      return_cost: "Return cost",
      instance_fee: "Instance fee",
      cod_fee: "Cod fee",
      admin_fee: "Admin fee",
      is_qualified: "Is qualified",
      is_contacted: "Is contacted",
      affbay_status: "Affbay status",
      is_visible: "Is visible",
      is_top: "Is top",
      is_desktop: "Is deskop",
      is_mobile: "Is mobile",
      url: "URL",
      tracker_id: "Tracker ID",
      tracker_type: "Tracker type",
      focus_ID: "Focus ID",
      instance: "Instance",
      signature: "Signature",
      value: "Value",
      courier_status: "Courier status",
      status_description: "Status description",
      notes: "Notes",
      additional_info: "Additional inf",
      external: "External",
      transactionType: "Transaction type",
      transactionStatus: "Transaction status",
      customVariables: "Custom variables",
      systemVariables: "System Variables",
      newVariable: "New variable",
      affbay_lead_id: "Affbay lead ID",
      amount: "Amount (USD)",
      optional_description: "Optional description",
      title: "Title",
      is_home_page: "Visible on dashboard",
      is_home_page_short: "On dashboard",
      news_type: "News type",
      visible_on_home_page: "Visible on dashboard",
      date_from: "Start form",
      date_from_short: "Start",
      date_to: "End form",
      date_to_short: "End",
      time: "Time",
      hour: "Hour",
      day: "Day",
      week: "Week",
      month: "Month",
      year: "Year",
      today: "Today",
      lastWeek: "Last week",
      lastMonth: "Last month",
      acceptedBalance: "Accepted Balance",
      acceptedBalanceExplanation: "(Sum of filtered records)",
      actualBalance: "Actual Balance",
      requestPayout: "Request Payout",
      notAcceptedBalance: "Not Accepted (Pending) Transactions:",
      earningsChart: "Earnings Chart",
      generatedOn: "Generated on",
      groupBy: "Group by",
      codeName: "Code name",
    },
    informations: {
      active: "Active",
      inactive: "Inactive",
      online: "Online",
      offline: "Offline",
      viewPassword: "View password",
      notAvailable: "n/a",
      qualified: "Qualified",
      notQualified: "Not qualified",
      sessionExpired: "Session expired",
      visible: "Visible",
      invisible: "Invisible",
    },
    modalButtonsTitle: {
      edit: "Edit",
      createNewInstance: "Add",
      createNewClassifier: "Add",
      addNewPage: "Add page",
      addNewCompany: "Add company",
      addNewPartner: "Add new Partner",
      addNewUser: "Add user",
      editUser: "Edit users",
      addNewRole: "Add new role",
      editRole: "Edit role",
      addNewCountry: "Add new country",
      addNewCity: "Add new city",
      addNewCourier: "Add new courier",
      addNewStatus: "Add new status",
      addNewLocation: "Add new location",
      addNewPostCode: "Add new post code",
      addNewMethod: "Add new method",
      addNewProvince: "Add new province",
      addNewChannel: "Add new channel",
      addNewSubdistrict: "Add new subdistrict",
      addNewCategory: "Add new category",
      addNewCampaign: "Add new campaign",
      editCampaign: "Edit campaign",
      addNewLeadsRedirection: "Add",
      addNewSystemVariable: "Add new variable",
      addNewTrafficSource: "Add new variable",
      addNewCouriersApiMapping: "Add new mapping",
      addNewCouriersStatuses: "Add new status",
      addNewSystemClassifier: "Add",
      deposit: "Deposit",
      withdraw: "Withdraw",
      addNew: "Add new",
    },
    modalTitle: {
      edit: "Edit",
      editPage: "Edit page",
      addNewCompany: "Add company",
      addNewUser: "Add new user",
      editUser: "Edit users",
      addNewRole: "Add new role",
      editRole: "Edit role",
      addNewCountry: "Add new country",
      editCountry: "Edit country",
      addNewCity: "Add new city",
      addNewCourier: "Add new courier",
      editCourier: "Edit courier",
      addNewDistrict: "Add new district",
      addNewStatus: "Add new status",
      editStatus: "Edit status",
      addNewLocation: "Add new location",
      editLocation: "Edit location",
      addNewPostCode: "Add new post code",
      editPostCode: "Edit post code",
      addNewMethod: "Add new method",
      editPaymentMethod: "Edit payment method",
      addNewCategory: "Add new category",
      editCategory: "Edit category",
      addNewChannel: "Add new channel",
      editChannel: "Edit channel",
      addNewSubdistrict: "Add new subdistrict",
      editSubdistrict: "Edit district",
      addNewProvince: "Add new province",
      deleteProvince: "Delete province",
      editCampaign: "Edit campaign",
      editLeadsRedirection: "Edit leads redirection",
      addNewSystemVariable: "Add new system variable",
      addNewTrafficSource: "Add new traffic source",
      addNewCouriersApiMapping: "Add new couriers api mapping",
      addNewCouriersStatuses: "Add new couriers status",
      editCouriersStatus: "Edit couriers status",
      editCouriersApiMapping: "Edit couriers api mapping",
      addNewSystemClassifier: "Add new system classifier",
      editSystemClassifier: "Edit system classifier",
      editTrafficSource: "Edit traffic source",
      deposit: "Deposit",
      withdraw: "Withdraw",
      transaction: "Transaction",
      addNew: "Add new",
      edit_news: "Edit news",
      sendFeedback: "Send feedback",
    },
    modalTooltips: {
      see_company_members: "See company members",
      addNewUser: "Add new user",
      addNewRole: "Add new role",
      addNewCountry: "Add new country",
      editCountry: "Edit country",
      addNewCity: "Add new city",
      editCity: "Edit city",
      addNewCourier: "Add new courier",
      editCourier: "Edit courier",
      addNewStatus: "Add new status",
      editStatus: "Edit status",
      addNewLocation: "Add new location",
      editLocation: "Edit location",
      addNewPostCode: "Add new post code",
      editPostCode: "Edit post code",
      addNewMethod: "Add new method",
      editCategory: "Edit category",
      addNewChannel: "Add new channel",
      editChannel: "Edit channel",
      addNewSubdistrict: "Add new subdistrict",
      editSubdistrict: "Edit district",
      addNewProvince: "Add new province",
      deleteProvince: "Delete province",
      addNewCategory: "Add new category",
      addNewSystemVariable: "Add new system variable",
      addNewTrafficSource: "Add new traffic source",
      editTrafficSource: "Edit traffic source",
      addNewCouriersApiMapping: "Add new couriers api mapping",
      addNewCouriersStatuses: "Add new couriers status",
      editCouriersApiMapping: "Edit couriers api mapping",
      editCouriersStatus: "Edit couriers statuse",
      addNewSystemClassifier: "Add new system classifier",
      editSystemClassifier: "Edit system classifier",
      filters: "Filters",
      deposit: "Deposit",
      withdraw: "Withdraw",
    },
    tables: {
      campainFocusTable: "CampainFocusTable",
    },
    title: {
      edit_account_manager: "Edit account manager",
      createNewInstance: "Create new instance",
      createNewClassifier: "Create new classifier",
      editClassifier: "Edit Classifier",
      editPartner: "Edit Partner",
      editEmployee: "Edit Employee",
      editCompany: "Edit company",
      addNewPartner: "Add new Partner",
      addNewEmployee: "Add new Employee",
      addNewCompany: "Add new company",
      selectInstance: "Select instance",
      selectInstanceAndCampaign: "Select instance and campaign",
      campaignsAllInstances: "Campaigns in all instances",
      all: "All",
      allArrowAll: "All -> All",
      allArrow: "All ->",
      arrowAll: "-> All",
      createNewProduct: "create new product",
      campaignsActivityStatus: `Change classifier activity status`,
      // partnerActivityStatus: `Change Partner activity status`,
      // employeesActivityStatus: `Change Employee activity status`,
      userActivityStatus: `Change users activity status`,
      filters: "Filters",
      activityStatus: `Change activity status`,
      addNewPage: "Add new page",
      addNewCampaign: "Add new campaign",
      addNewLeadsRedirection: "Add new leads redirection",
      notFoundLabel: "Sorry, the page you visited does not exist.",
      addNew: "Add new",
      new: "New",
      change: "Change",
      inProgressTitle: "Page in progress",
      inProgressDesc: "Sorry, the page you visited is still in progress.",
    },
    columnTitle: {
      product_sku: "Product SKU",
      product_sku_short: "SKU",
      comment: "Comment",
      classifier_end: "Classifier end",
      phone_origin: "Phone (origin)",
      id: "ID",
      type: "Type",
      country: "Country",
      country_short: "Co.",
      name: "Name",
      second_name: "Second name",
      short_name: "Short name",
      campaign_name: "Campaign name",
      domain: "Domain",
      user: "User",
      password: "Password",
      password_short: "Pass",
      url: "Base campain URL",
      url_short: "URL",
      active: "Actual campaign activity status",
      active_short: "Active",
      // classifier_is_qualified: 'Actual classifier qualified status',
      // classifier_is_qualified_short: 'Qualified',
      online: "Actual campaign online status",
      online_short: "Online",
      created: "Date created",
      created_short: "Created",
      updated: "Recently Updated",
      updated_short: "Updated",
      options: "Options",
      instance: "Instance",
      focus_instance: "Focus instance",
      classifiers_external: "Classifiers external",
      affbay_status: "Affbay status",
      status: "Status",
      qualified: "Qualified",
      approvedAt: "Approved",
      approvedAt_short: "Date approved at",
      classifier: "Classifier",
      product: "Product",
      partner: "Partner",
      payout: "Payout",
      payout_scheme: "Payout Schema",
      payout_scheme_short: "Scheme",
      phone: "Phone",
      click_id: "click_id",
      pub_id: "Pub ID",
      lp_url: "Lp URL",
      role: "Role",
      description: "Description",
      application_access: "Application access",
      avalable_in_affbay: "Available in Affbay",
      avalable_in_affbay_short: "In Affbay",
      avalable_for_sale: "Available for sale",
      avalable_for_sale_short: "For sale",
      base_price: "Base prices",
      base_price_short: "Price",
      visible_for_partners: "Visible for partners",
      visible_for_partners_short: "For partners",
      categories: "Categories",
      landing: "Landing",
      preview: "Preview",
      is_visible: "Visible",
      is_top: "Top",
      is_mobile: "Mobile",
      is_desktop: "Desktop",
      deleted_at: "Deleted",
      accountCounter: "Account counter",
      isInternal: "Internal",
      partnerToken: "Partner token",
      email: "Email",
      manager: "Manager",
      company: "Company",
      skype: "Skype",
      telegram: "Telegram",
      is_active_short: "Active",
      key: "Key",
      focusId: "Focus ID",
      is_operative: "Operative",
      address: "Address",
      code: "Code",
      currency: "Currency",
      is_active: "Active",
      name_eng: "Name (eng)",
      bank_transfer: "Bank transfer",
      city: "City",
      province: "Province",
      district: "District",
      subdistrict: "Subdistrict",
      postCode: "Post code",
      courier: "Courier",
      lead_time: "Lead time",
      lead_time_text: "Lead time text",
      district_code: "District code",
      island: "Island",
      deliver_cost: "Deliver cost",
      resturn_cost: "Return cost",
      insurance_fee: "Insurance fee",
      code_fee: "Code fee",
      admin_fee: "Admin fee",
      dalivery_cost: "Delivery cost",
      permission_id: "Permission ID",
      is_qualified: "Qualified",
      is_contacted: "Contacted",
      is_qualified_short: "Qualified",
      value: "Value",
      courier_name: "Courier name",
      courier_name_short: "Courier",
      courier_status: "Courier status",
      status_description: "Status description",
      notes: "Notes",
      external: "External",
      additional_inf: "Additional informations",
      additional_inf_short: "Informations",
      view: "View",
      is_manual_short: "Manual",
      is_manual: "Is Manual",
      is_successful_short: "Successful",
      is_successful: "Is Successful",
      response_time_short: "Time",
      response_time: "Response time",
      response_status_short: "Status",
      response_status: "Response Status",
      response_text_short: "Text",
      response_text: "Response Text",
      title: "Title",
      is_home_page: "Visible on dashboard",
      is_home_page_short: "On dashboard",
      news_type: "News type",
      visible_on_home_page: "Visible on dashboard",
      date_from: "Start form",
      date_from_short: "Start",
      date_to: "End form",
      date_to_short: "End",
      scheme: "Scheme",
      leadId: "Lead ID",
      accepted: "Accepted",
      date: "Date",
      params: "Params",
      exclude_from_double: "Exclude from double",
      exclude_from_double_short: "Double Excl.",
    },
    tooltips: {
      see_company_members: "See company members RU",
      hide_postbacks: "Hide postbacks",
      show_postbacks: "Show postbacks",
      copyKey: "Copy key",
      editPage: "Edit page",
      deletePage: "Delete page",
      cantDeleteAdminSuperAdmin: `Can't delete Admin/Super admin`,
      userActivityStatus: "Change users activity status",
      deleteUser: "Delete user",
      changeRoleActiveStatus: "Change roles sctive status",
      editRole: "Edit role",
      deleteRole: "Delete role",
      changeActiveStatus: "Change active status",
      viewCampaign: "View campaign",
      viewCompany: "View company",
      changeQualifiedStatus: "Change qualified status",
      deleteStatus: "Delete status",
      deletePostCode: "Delete post code",
      deleteCstegory: "Delete category",
      change_activity_status: "Change activity status",
      delete_leads_direction: "Delete direction",
      edit_leads_direction: "Edit leads redirection",
      delete: "Delete",
      edit: "Edit",
      accept: "Accept",
      reject: "Reject",
    },
    statement: {
      unrecognized_error: "Unrecognized error",
      refresh_page: "Refresh page",
      your_account_has_been_created: "Your account has been activated",
      your_account_has_been_created_message:
        "Now you need to wait for our specialist to accept your account. ",
      sorry_we_could_not_get_your_data: `Sorry, we couldn't get your data`,
      databaseConnectionError:
        "Something went wrong while connecting to the database!",
      databaseConnectionSuccess: "Udane pobranie z bazy danych!",
      instanceCreated: "Udane dodanie instancji!",
      classifierCreated: "Classifier created!",
      classifierUpdated: "Classifier updated!",
      thePropsAreTheSame:
        "Wartości w polach nie uległy zmianie - do ustalenia co z tym robimy",
      campaignUpdated: "Udana aktualizacja kampanii!",
      qulifiedUpdate: "Udana zmiana kwalifikacji",
      app_user_updated: "App user updated!",
      app_user_created: "App user created!",
      status_updated: "Status updated!",
      there_was_a_problem_removing_app_user:
        "There was a problem removing app user",
      app_user_deleted: "App user deleted",
      there_was_a_problem_removing_role: "There was a problem removing role",
      there_was_a_problem_removing_permission:
        "There was a problem removing permission",
      there_was_a_problem_creating_role: "There was a problem creating role",
      user_role_updated: "User role updated!",
      companie_created: "Companie created!",
      companie_updated: "Companie updated!",
      there_was_a_problem_removing_city: "There was a problem removing city",
      city_deleted: "City deleted!",
      city_created: "City created!",
      city_updated: "City updated!",
      there_was_a_problem_removing_country:
        "There was a problem removing country",
      country_created: "Country deleted!",
      country_updated: "Companie updated!",
      there_was_a_problem_removing_courier:
        "There was a problem removing courier",
      courier_deleted: "Courier deleted!",
      courier_updated: "Courier updated!",
      courier_created: "Courier created!",
      there_was_a_problem_removing_district:
        "There was a problem removing district",
      district_deleted: "District deleted!",
      district_updated: "District updated!",
      district_created: "Dictrict created!",
      there_was_a_problem_removing_location:
        "There was a problem removing location",
      location_deleted: "Location deleted!",
      location_updated: "Location updated!",
      location_created: "Location created!",
      there_was_a_problem_removing_payment_method:
        "There was a problem removing payment method",
      payment_method_deleted: "Payment method deleted!",
      payment_method_updated: "Payment method updated!",
      payment_method_created: "Payment method created!",
      there_was_a_problem_removing_post_code:
        "There was a problem removing post code",
      post_code_deleted: "Post code deleted!",
      post_code_updated: "Post code updated!",
      post_code_created: "Post code created!",
      there_was_a_problem_removing_product_category:
        "There was a problem removing product category",
      product_category_deleted: "Product category deleted!",
      product_category_updated: "Product category updated!",
      product_category_created: "Product category created!",
      there_was_a_problem_removing_province:
        "There was a problem removing province",
      province_deleted: "Province deleted!",
      province_updated: "Province updated!",
      province_created: "Province created!",
      there_was_a_problem_removing_sales_channel:
        "There was a problem removing sales channel",
      sales_channel_deleted: "Sales channel deleted!",
      sales_channel_updated: "Sales channel updated!",
      sales_channel_created: "Sales channel created!",
      there_was_a_problem_removing_status:
        "There was a problem removing status",
      status_deleted: "Status deleted!",
      status_created: "Status created!",
      there_was_a_problem_removing_subdistrict:
        "There was a problem removing subdistrict",
      subdistrict_deleted: "Subdistrict deleted!",
      subdistrict_updated: "Subdistrict updated!",
      subdistrict_created: "Subdistrict created!",
      employee_updated: "Employee updated!",
      employee_created: "Employee created!",
      filters_confirmed: "Filtry potwierdzone",
      filters_have_been_reset: "filters have been reset",
      there_was_a_problem_removing_page: "There was a problem removing page",
      page_deleted: "Page deleted!",
      page_updated: "Page updated!",
      page_created: "Page created!",
      partner_updated: "partner updated!",
      partner_created: "partner created!",
      change_permission_status: "Change permissions status",
      there_was_a_problem_removing_campaign:
        "There was a problem removing campaign",
      there_was_a_problem_creating_campaign:
        "There was a problem creating campaign",
      campaign_deleted: "Campaign deleted!",
      campaign_updated: "Campaign updated!",
      campaign_created: "Campaign created!",
      role_deleted: "Role deleted!",
      choose_out_of_scope: "Choose out of the scope!",
      date_changed: "Date changed",
      session_expired: "Session expired",
      session_expired_description:
        "Your session has expired. Please login again to continue working",
      incorrect_login_information:
        "Your login information was incorrect. Please check and try again",
      there_was_a_problem_removing_system_variable:
        "There was a problem removing system variable",
      system_variable_deleted: "System variable deleted!",
      system_variable_updated: "System variable updated!",
      system_variable_created: "System variable created!",

      traffic_source_deleted: "Traffic source deleted!",
      traffic_source_updated: "Traffic source updated!",
      traffic_source_created: "Traffic source created!",

      there_was_a_problem_removing_couriers_api_mapping:
        "There was a problem removing couriers api mapping",
      couriers_api_mapping_deleted: "Couriers api mapping deleted!",
      couriers_api_mapping_updated: "Couriers api mapping updated!",
      couriers_api_mapping_created: "Couriers api mapping created!",
      system_classifier_created: "System classifierl created!",
      system_classifier_updated: "System classifierl updated!",
      updated: "Updated!",
      created: "Created!",
      deleted: "Deleted!",
      success: "Success!",
      accepted: "Accepted!",
      rejected: "Rejected",
      noCompanyIdAssigned:
        "Your account has no company assigned. Please contact us through support panel.",
      noDataToDisplay: "Sorry, there is no data to display",
    },
    tabs: {
      createNewProduct: "create new product",
      pricing: "Pricing",
      conversion: "Conversion",
      affbayVisibility: "Affbay visibility",
      landings: "Landings / Prelandings",
    },
    navigationItems: {
      dashboard: "Dashboard",
      statistics: "Statistics",
      financeStatistics: "Finance statistics",
      leads: "Leads",
      employees: "Employees",
      products: "Products",
      pages: "Pages",
      landings: "Landings",
      companies: "Companies",
      partners: "Partners",
      campaigns_partner: "Campaigns",
      campaigns_admin: "Campaigns admin",
      finance: "Finance",
      financeAdmin: "Finance admin",
      tools: "Tools",
      news: "News",
      news_admin: "News admin",
      support: "Support",
      faq: "FAQ",
      contact: "Contact",
      login: "Login",
      register: "Register",
      offers: "Offers",
      create_new_order: "Create new order",
      orders: "Orders",
      couriersStatusMapping: "Status mapping",
      account_managers: "Account Managers",
    },
    subNavigationItem: {
      k8_services: "K8 Services",
      sales: "Sales",
      sales_p2: "P2",
      sales_resale: "P3",
      sales_winback: "Winback",
      administration: "Administration",
      appRemarketing: "Remarketing - P2",
      appUsers: "Users",
      appUsersRole: "Users role",
      webSocket: "WebSocket",
      appUsersPermissions: "Users permissions",
      // Call center
      callCenter: "Call center",
      cc_instances: "Instances",
      cc_campaigns: "Campaigns",
      cc_classifiers: "Classifiers",
      li_classifiers_cc: "Mapping classifiers",
      cc_webhooks: "Webhooks",
      cc_leads_direction: "Leads redirection",
      // Settings
      setting: "Setting",
      api: "API",
      payment: "Payment",
      postbacks: "Postbacks",
      referralHistory: "Referral history",
      security: "Security",
      settings: "Settings",
      // Dictionaries
      dictionaries: "Dictionaries",
      countries: "Countries",
      cities: "Cities",
      couriers: "Couriers",
      districts: "Districts",
      subdistricts: "Subdistricts",
      statuses: "Statuses",
      locations: "Locations",
      postCodes: "Post cods",
      paymentMethods: "Payment methods",
      productCategories: "Product cat.",
      salesChannels: "Sales channels",
      provinces: "Provinces",
      system_variables: "System variables",
      traffic_sources: "Traffic sources",
      couriers_api_mapping: "Couriers mapping",
      couriers_statuses: "Couriers statuses",
      system_classifiers: "System classifiers",
    },
    others: {
      of: "of",
      rows: "rows",
    },
    contentTitle: {
      support: "Support",
    },
    contentText: {
      alreadyHaveAnAccount: "Already have an account?",
      iDontHaveAnAccount: "I don't have an account.",
      rememberThePassword: "Remember the password?",
      support:
        "If you need help with your account, have questions about using the platform or have technical problems, write to us.",
    },
    links: {
      all_news: "All news",
      login: "Login",
      registration: "Registration",
      forgotYourPassword: "Forgot your password?",
    },
    placeholders: {
      enterName: "Enter name",
      enterEmail: "Enter e-mail",
      enterPassword: "Enter password",
      repeatPassword: "Repeat password",
      selectCountry: "Select country",
      notRequired: "Not required",
      select: "Select...",
      globalSearch: "Global search",
      search: "Search",
      findProduct: "Find product",
      choose_affbay_status: "Choose affbay status",
      choose_country: "Choose country",
      choose_company: "Choose company",
      choose_product: "Choose product",
      choose_partner: "Choose partner",
      choose_scheme: "Choose scheme",
      choose_classifier: "Choose classifier",
      choose_remarketing_campaign: "Choose campaign",
      enter_min_4_letters: "Entere min 4 letters",
    },
    pageTitle: {
      k8_services: "K8 Services",
      dashboard: "Dashboard [IDR]",
      account_managers: "Account managers",
      loginContent: "Log In",
      registerContent: "Registration",
      resetPasswordContent: "Restore password",
      employees: "All employees PL + All employees Call center",
      products: "Products",
      pages: "Pages",
      addNewCompany: "Add company",
      companies: "Companies",
      partners: "Partners",
      administrationUsers: "Administration - Users",
      administrationUsersRole: "Administration - Users Role",
      instances: "Instances",
      instancesForCCSimplified: "Call center API connection",
      campaigns: "Campaigns",
      classifiers: "Classifiers",
      mappingClassifiers: "Mapping classifiers",
      webhooks: "Webhooks",
      countriesDictionary: "Countries dictionary",
      citiesDictionary: "Cities dictionary",
      couriersDictionary: "Couriers dictionary",
      districtsDictionary: "Districts dictionary",
      statusesDictionary: "Statuses dictionary",
      locationsDictionary: "Locations dictionary",
      postCodeDictionary: "Post codes dictionary",
      paymentMethodDictionary: "Payment methods dictionary",
      productCategoriesDictionary: "Product categories dictionary",
      salesChannels: "Sales channels dictionary",
      subdistrictsDictionary: "Subdistricts dictionary",
      provincesDictionary: "Provinces dictionary",
      campaigns_admin: "Campaigns - Admin",
      orders: "Orders",
      leads_directions: "Leads redirections",
      system_variables: "System variables",
      traffic_sources: "Traffic sources",
      couriers_api_mapping: "Couriers api mapping",
      system_classifiers: "System classifiers",
      finance: "Finance",
      news_admin: "News Admin",
      finance_admin_view: "Finance admin view",
      resaleP3: "P3 Resale",
      resaleP2: "P2 Resale",
    },
    productsFormMain: {
      editProduct: "Edit product",
      createNewProduct: "Create new product",
      englishName: "English name",
      currency: "Currency",
      callCenter: "Call Center",
      sku: "SKU",
      originalName: "Original name",
      shortName: "Short name",
      printName: "Print name",
      categories: "Categories",
      availableInAffbay: "Available in AFFBAY",
      testProduct: "Test product",
      availableForSale: "Available for sale",
      active: "Active",
      country: "Country",
      sales: "Sales",
      mainCamapignId: "Main campaign [id]",
      resellCampaignId: "Resell campaign [id]",
      productionCost: "Production cost (USD)",
      productionCostLocalCurrency: "Production cost (local currency)",
      affbayVisibility: "Affaby Visibility",
      visibleForPartners: "Visible for partners",
      visibleOnHomePage: "Visible on home page (top offers)",
      isRestricted: "Is restricted",
      defaultGlobalPayout: "Default global payout (USD)",
      defaultPayoutScheme: "Default payout scheme",
      cpa: "CPA",
      cpl: "CPL",
      landingPriceForConsumer: "Landing price for consumer",
      approveVisibleOnPage: "Approve visible on page (%)",
      payoutVisibleOnPage: "Payout visible (string) on page (USD)",
      descriptionEN: "Description EN",
      descriptionRU: "Description RU",
      traffic_sources_en: "Traffic sources (description) EN",
      traffic_sources_ru: "Traffic sources (description) RU",
      terms_en: "Terms EN",
      terms_ru: "Terms RU",
      conditions_en: "Conditions EN",
      conditions_ru: "Conditions RU",
      productsWillNotBeAvailableInAffbay:
        "Products will not be available in AFFBAY",
      updateCampaigns: "Refresh campaings list",
      save: "Save",
      create: "Create",
      reset: "Reset",
    },
    checkBoxes: {
      iWantToReceiveImportantNewsByEmail:
        "I want to receive important news by email (recommended)",
      iWantToReceivePromotionslNewsByEmail:
        "I want to receive promotional news by email",
      iWantToReceiveInformationAboutChangesInOffersByEmail:
        "I want to receive information about changes in offers by email",
      iWantToReceiveOffersOfOffersByEmail:
        "I want to receive offers of offers by email",
      add_to_affbay_classifiers_library: "Add to Affbay Classifiers library",
    },
    landingPage: {
      about: "About",
      topOffers: "Top offers",
      contact: "Contact",
      logIn: "Log In",
      certifiedProducts: "CERTIFIED PRODUCTS FROM DIRECT ADVERTISER",
      afiliateNetwork: "Affiliate Network in Thailand",
      saudiArabAndIndonesia: "Saudi Arabia and Indonesia",
      becomeAPartner: "Become a partner",
      activePartners: "Active partners",
      offersInAsia: "Offers in Asia",
      yearsOnTheMarket: "Years on the market",
      countries: "Countries",
      aboutText:
        "Affbay is a direct advertiser in the markets of Asia and the Arab countries. We do not resell the offers, all products are our own. Here you will find dozens of offers on the topic of beauty, health and weight loss with payments from $ 18 higher for a confirmed application. We work on models CPA, CPL, CPI.",
      regularPayouts: "Regular Payouts",
      regularPayoutsText: "Payouts without delay on most payment systems.",
      highPayouts: "High Payouts",
      highPayoutsText: "From $ 18 and above for a confirmed application.",
      ownCallCenter: "Own call center",
      ownCallCenterText:
        "Experienced CC specialists performing work efficiently.",
      personalManager: "Personal manager",
      personalManagerText:
        "Responsive support will help you solve any problems.",
      weDontHaveTopOffers: "We don't have any top offers right now.",
      howCanWeHelpYou: "How can we help you?",
      writeUs: "Write us about your concerns.",
      becomeOurPartner: "Become our partner and start earning right now",
      affbayAsia: " Affbay.asia",
      ownedBy: "Owned By: Izabella Frolushkin FZE",
      privacyPolicy: "Privacy Policy",
      termsAndConditions: "Terms and Conditions",
      send: "Send",
      name: "Name",
      email: "E-mail",
      object: "Object",
      message: "Message",
      thankYou: "Thank you",
      youWillBeHearing:
        "Your message was sent. We will handle your issue with 48 hours.",
      startEarning: "Start earning",
      sitePrice: "Site price",
      approve: "Approve",
      payouts: "Payouts",
      country: "Country",
    },
    loginPage: {
      logIn: "Log In",
      registration: "Registration",
      email: "E-mail",
      password: "Password",
      forgotYourPassword: "Forgot your password?",
      logIntoThePlatform: "Log into the platform",
      iDontHaveAnAccount: "I don't have an account.",
      send_request_again: "Send request again",
    },
    registrationPage: {
      registration: "Registration",
      name: "Name",
      email: "E-mail",
      password: "Password",
      repeatPassword: "Repeat password",
      country: "Country",
      skype: "Skype",
      telegram: "Telegram",
      notRequired: "Not required",
      iAgreeToAffbayAsia: "I agree to Affbay Asia",
      privacyPolicy: "Privacy Policy",
      and: "and",
      termsAndConditions: "Terms and Conditions",
      nextStep: "Next Step",
      alreadyHaveAnAcount: "Already have an account?",
      logIn: "Log in",
      thxForReg: "Thank you for registration",
      toStartWork: "To start work please provide us few information",
      surveyIsNotAvailable: "Survey is not available",
      thisAccountIsA: "This account is a...",
      whichgeos: "Which geos you have worked with before?",
      whatgeo: "What geos do you want to work with?",
      whatTypeOfTraffic: "What type of traffic are you using?",
      whatBugdets: "What budgets did you work with?",
      whatDoYouPrefer: "What do you prefer - S2S or API?",
      submitMyApplication: "Submit my application",
      enterYourName: "Enter your name",
      enterEmail: "Enter e-mail",
      enterPassword: "Enter password",
    },
    submitSuccess: {
      congrats: "Congratulations, your account has been created!",
      nowYouCan: "Now you can login to your account",
      logIn: "Log In",
    },
    resetPassword: {
      password_changed: "Your password has been changed. You can login now.",
      typeTheAdress:
        "Type the address linked to your account and we'll send you password reset instructions. Also, check your spam folder.",
      email: "E-mail",
      set_new_password: "Set new password",
      remember: "Remember your password?",
      logIn: "Log In",
      checkEmail: "Check your e-mail",
      withinAFew:
        "Within a few minutes you will receive an email. In this message we will send you a link to set new password.",
      new_password: "New password",
    },
    supportView: {
      support: "Support",
      ifYouNeedHelp:
        "If you need help with your account, have questions about using the platform or have technical problems, write to us.",
      subject: "Subject",
      message: "Message",
      send: "Send",
    },
    newsView: {
      news: "News",
      allNews: "All news",
    },
    offersView: {
      offers: "Offers",
      offersFound: "offers found",
      show: "Show:",
      sortBy: "Sort by:",
      category: "Category",
      conversionType: "Conversion type",
      sort: "Sort",
      accessRequest: "Access Request",
      wirteUs:
        "Write us about traffic sources, with whom you work and your experience.",
      send: "Send",
      thankYou: "Thank you",
      youWillBeHearing: "You will be hearing from us soon!",
      sitePrice: "Site price",
      payouts: "Payouts",
      country: "Country",
      originalCountry: "Original country",
      readMore: "Read more",
      exampleCodePhp: "Download example",
    },
    offersDetails: {
      sku: "SKU",
      // tabs
      description: "Description",
      targetURL: "Target URL",
      postbacks: "Postbacks",
      trafficSources: "Traffic sources",
      news: "News",
      terms_and_conditions: "Terms & conditions",
      landings_tab: "Landings",
      // product card
      payout: "Payout",
      sitePrice: "Site price",
      category: "Category",
      country: "Country",
      devices: "Devices",
      // target url
      step_1:
        "Step 1. Choose landing page and prelanding. You can choose multiple items which be opened randomly in your campaign",
      step_2:
        "Step 2. Enter new name for your campaign and click Generere URL button to save it.",
      landings: "Landings",
      prelandings: "Prelandings",
      campaign_name: "Campaign name",
      generateURL: "Generate URL",
      allProductCampaigns: "All product campaigns",
      name: "Name",
      prelanding: "Prelanding",
      landing: "Landing",
      URL: "URL",
      youHaveTo: "You have to choose LP and type Campaign name",
      noLandings: "No pages to display",
      // postbacks
      urlForSales: "Postback URL for Sales (means APPROVE for CPL)",
      urlForHolds: "Postback URL for Holds (means APPROVE for CPL)",
      urlForRejects: "Postback URL for Rejects (means APPROVE for CPL)",
      urlForTrash: "Postback URL for Trash (means REJECTED for CPL)",
      urlForDuplicated: "Postback URL for Duplicated (means REJECTED for CPL)",
      urlForExcluded: "Postback URL for Excluded (means REJECTED for CPL)",
      urlForValidated: "Postback URL for Validated",
      googleTrackingID: "Google tracking ID",
      facebookPixelID: "Facebook pixel ID",
      customPixelID: "Custom pixel ID",
      useGlobalPostbacks: "Use global postbacks",
      createPostback: "Create postback",
      updatePostback: "Update postback",
      fillInTheData: "Fill in the data below to create a new postback",
      protocol: "Protocol",
      domain: "Domain",
      exampleCOM: "example.com",
      extraPathParameter: "Extra path parameter",
      folderSlash: "/folder/subfolder",
      parameter: "Parameter",
      addNew: "Add new",
      postbackURLPreview: "Postback URL Preview",
      save: "Save",
      basic: "Basic",
      advanced: "Advanced",
      postbackSavedSuccessfully: "Postback saved successfully",
      postbackSavedWithWarning: "Postback saved with warning",
      postbackNotSaved: "Unable to saved postback",
      clearPostback: "Clear Postback",
    },
    profileView: {
      personlaData: "Personal data",
      changePassword: "Change password",
      notifications: "Notofications",
      apitoken: "API Token",
      // personal data
      email: "E-mail",
      name: "Name",
      phone: "Phone number",
      telegram: "Telegram",
      skype: "Skype",
      timeZone: "Time zone",
      apply: "Apply",
      cancel: "Cancel",
      id_referral: "Registration link",
      // change password
      currentPass: "Current password",
      newPass: "New password",
      repeatNewPass: "Repeat new password",
      change: "Change",
      // notifications
      importantNews: "I want to receive important news by email (recommended)",
      promotionalNews: "I want to receive promotional news by emial",
      information:
        "I want to receive information about changes in offers by email",
      offers: "I want to receive offers of offers by email",
      customVariablesList: "Custom Variables List",
      postbacksList: "Postbacks List",
      // api token
    },
    toolsView: {
      open: "Open",
      tools: "Tools",
      // faq
      faq: "FAQ",
      answersToFAQ:
        "Answers to frequently asked questions regarding payments for their implementation and Affbay in general",
      readMore: "Read more",
      // API Documentation
      apiDocs: "API Documentation",
      leadSendExample: "Send lead example form",
      readOurDocs:
        "Read our documentation to get answers to many questions regarding the API.",
      readDocs: "Read documentation",
      // postback documentation
      postbackDocs: "Postback Documentation",
      postbacksNotify:
        "Read our documentation to get answers to many questions regarding the API.",
      // integration archi model
      integrationArchiModel: "Integration Architecture Model",
      hardToUnderstand:
        "Hard to understand how Affbay works? Read more about a few examples of our architecture.",
      // number validation
      numberValidation: "Number validation",
      checkingTheNumber:
        "Checking the number is the key to improving the quality of customer acquisition and revenue.",
      // longpage widget
      longpageFromWidget: "Longpage form widget",
      longpageFromWidgetDescription:
        "Include our form into your webpage. Simply paste our code, add your token and sku to start generating new leads.",
    },
    select: {
      select_timezone: "Select timezone",
      select_status: "Select status",
      select_available: "Select available",
      select: "Select",
      select_country: "Select country",
    },
    spin: {
      loading: "Loading...",
    },
    faq: {
      header_1: "What is Affbay Asia?",
      header_2: "What kinds of integration do we support?",
      header_3: "Do you need your own tracker to integrate by S2S?",
      header_4: "Which parameters are supported by Affbay (S2S integration)?",
      header_5: "How to add parameters you want to receive?",
      header_6: "Which parameter is Affbay’s?",
      header_7: "How to configure general postbacks for S2S?",
      header_8: "What is postback for sales, holds, rejected?",
      header_9: "How to configure getting postback for concrete offer?",
      header_10: "How to add payout to postback url?",
      header_11: "How should a postback url look like?",
      content_1:
        "We’re direct advertisers in Indonesia and Thailand, we have our own high conversion COD offers. More geos in Asia coming soon!",
      content_2:
        "We can integrate with you by S2S or by API. Documentation for API is provided on our website in section Offers (at the top of webpage). Choose offers you like and click Details.",
      content_3:
        "Affbay is a user friendly platform, which provides you with your personal account with dashboard and requires no tracker, although we can provide you S2S postback integration with your trackers.",
      content_4:
        'You can add any parameter that is supported by your tracker. See "How to add parameters you want to receive?".',
      content_5:
        "Enter your personal account -> click your User name -> Profile -> Global postbacks. Just add any parameter without brackets {} () and put it also in your postback url.",
      content_6: [
        "Our built in parameters are:",
        "click_id - global identifier (UUID) of a specific lead",
        "campaign_id - global identifier (UUID) of a specific campaign",
        "aff_id - global identifier (UUID) of a specific user (you).",
        "If you specify a custom variable named as one of the above, postback will use it, substituting your tracker data instead of our default data. We discourage this to avoid confusion.",
      ],
      content_7:
        "Enter your personal account -> click your User name -> Profile -> Global postbacks. Here you can add you general postback url. If you want to receive postbacks for sales – just put your postback url in this field, the same for postback for holds and rejected. Also you can add Facebook Pixel and Google Tracking ID for all the offers.",
      content_8:
        "If you want to receive postback for approved leads – choose postback for sales. Postback for holds means getting postback for every lead that came into our system. Postback for rejected means getting postback for leads that were rejected – invalid phone number or leads not being reached by Call Center in a month.",
      content_9:
        "Enter section Offers (at the top of webpage) -> choose offers and click Details -> there you can add postback urls and also Facebook Pixel and Google Tracking ID for specific offer.",
      content_10: "&amount=13&payout_type=pps¤cy=USD",
      content_11:
        "https://callbacks.bitterstrawberry.org/?token=0c4c1249825285a2997bacf09c09ec28&hash={hash}&transaction_id={click_id}&amount=13&payout_type=pps¤cy=USD",
    },
    news_type: {
      all_news: "All news",
      new: "New",
      important: "Important",
      info: "Info",
    },
    setting_page: {
      click_id: "Click ID",
      check_click_id: "Check click ID doubles",
      click_id_days: "Number of days to check",
    },
  },
});
