import CheckboxF from "../../../../components/templateForm/form/checkBox/CheckboxF";
import React from "react";
import styles from "./styles/RegisterForm.module.scss";
import TextInputF from "../../../../components/templateForm/form/textInput/TextInputF";
import { Button } from "antd";
import { createAbsoluteUrl } from "../../../../helpers/UrlCreator";
import { createUser } from "../../../../services/m29_users_management/GeneralUserService";
import { emailIsValid } from "../../../../helpers/ValidationHelpers";
import { Form, FormikBag, FormikErrors, FormikProps, withFormik } from "formik";
import { IReferrer } from "../../interfaces/IReferrer";
import { IRegisterData } from "./interfaces/IRegisterData";
import { IRegisterFormProps as IFormProps } from "./interfaces/IRegisterFormProps";
import { IRegisterFormValues as IFormValues } from "./interfaces/IRegisterFormValues";
import { IRegisterUser } from "../../../../models/IRegisterUser";
import { resources } from "../../../../common/Resources";

const lang: string = localStorage.getItem("locale")
  ? localStorage.getItem("locale")!
  : "en_GB";

const formikTranslation: any = {
  en_GB: {
    name: "Name",
    email: "E-mail",
    password: "Password",
    repeatPassword: "Repeat password",
    country: "Country",
    skype: "Skype",
    telegram: "Telegram",
    notRequired: "Not required",
    logIn: "Log in",
    enterYourName: "Enter your name",
    enterEmail: "Enter e-mail",
    enterPassword: "Enter password",
  },
  id_ID: {
    name: "Имя",
    email: "E-mail",
    password: "Пароль",
    repeatPassword: "Повторите пароль",
    country: "Страна",
    skype: "Skype",
    telegram: "Telegram",
    notRequired: "Не обязательно",
    logIn: "Войти",
    enterYourName: "Введите имя",
    enterEmail: "Введите e-mail",
    enterPassword: "Введите пароль",
  },
};

export class InnerRegisterForm extends React.Component<
  IFormProps & FormikProps<IFormValues>
> {
  public static defaultProps = {
    readOnly: false,
    disabled: false,
    allowClear: true,
    labelTextAlignLeft: true,
  };

  state = {
    disable_button: false,
  };

  public render() {
    return (
      <section className={`${styles.grid} customAnt--regForm`}>
        <Form>
          <div className={styles.formItem}>
            <TextInputF
              {...this.props}
              name="name"
              label={formikTranslation[lang].name}
              placeholder={formikTranslation[lang].enterYourName}
              disabled={this.props.values.loading}
              required
            />
          </div>
          <div className={styles.formItem}>
            <TextInputF
              {...this.props}
              name="email"
              label={formikTranslation[lang].email}
              placeholder={formikTranslation[lang].enterEmail}
              disabled={this.props.values.loading}
              required
            />
          </div>
          <div className={styles.formItem}>
            <TextInputF
              {...this.props}
              name="password"
              label={formikTranslation[lang].password}
              isPassword
              placeholder={formikTranslation[lang].enterPassword}
              disabled={this.props.values.loading}
              required
            />
          </div>
          <div className={styles.formItem}>
            <TextInputF
              {...this.props}
              name="repeatPassword"
              label={formikTranslation[lang].repeatPassword}
              isPassword
              placeholder={formikTranslation[lang].repeatPassword}
              disabled={this.props.values.loading}
              required
            />
          </div>
          <div className={styles.formItem}>
            <TextInputF
              {...this.props}
              name="skype"
              label={formikTranslation[lang].skype}
              placeholder={formikTranslation[lang].notRequired}
              disabled={this.props.values.loading}
            />
          </div>
          <div className={styles.formItem}>
            <TextInputF
              {...this.props}
              name="telegram"
              label={formikTranslation[lang].telegram}
              placeholder={formikTranslation[lang].notRequired}
              disabled={this.props.values.loading}
            />
          </div>
          <div className='my-3 flex gap-3'>
            <CheckboxF
              {...this.props}
              name="policy"
              disabled={this.props.values.loading}
            />
            <p className={`h6`}>
              {resources.registrationPage.iAgreeToAffbayAsia}&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://drive.google.com/file/d/1N2iv6PVjKl_kcrgPWrQkoQDI5NZQujqp/view?usp=sharing"
                className={`h6__semibold ${styles.link} ${styles.link__inline}`}
              >
                {resources.registrationPage.privacyPolicy}
              </a>{" "}
              {resources.registrationPage.and}&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://drive.google.com/file/d/16KHkPbDc8FdysBsxAvHEtMBcq-SNXq0i/view?usp=sharing"
                className={`h6__semibold ${styles.link} ${styles.link__inline}`}
              >
                {resources.registrationPage.termsAndConditions}
              </a>
            </p>
          </div>
          <div className="w-280 h-44 relative bg-gradient-to-r bg-no-repeat from-blue to-aquamarine rounded-md">
            <Button
              className="hover:bg-transparent hover:text-white text-white w-278 h-41 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-gray font-semibold text-xxs rounded-md"
              htmlType="submit"
              disabled={this.state.disable_button}
              loading={this.props.values.loading}
              onClick={() => {
                this.props.submitForm();
                this.disableButton();
              }}
            >
              {resources.registrationPage.nextStep}
            </Button>
          </div>
            <div className='mt-2 text-center'>
              <p className={`h6`}>
                {resources.registrationPage.alreadyHaveAnAcount}{" "}
                <a
                  href={createAbsoluteUrl("login")}
                  className={`h6__semibold ${styles.link} ${styles.link__inline}`}
                >
                  {resources.registrationPage.logIn}
                </a>
              </p>
            </div>
        </Form>
      </section>
    );
  }

  disableButton = () => {
    this.setState({ disable_button: true });
    setTimeout(() => this.setState({ disable_button: false }), 5000);
  };
}

const RegisterForm = withFormik<IFormProps & IReferrer, IFormValues>({
  mapPropsToValues: () => {
    return {
      name: "",
      email: "",
      password: "",
      repeatPassword: "",
      skype: "",
      telegram: "",
      policy: false,
    };
  },

  validate: (values: IFormValues) => {
    const errors: FormikErrors<IFormValues> = {};
    const reg = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

    if (!values.name) {
      errors.name = resources.validation.nameIsRequired;
    }
    if (values.name && values.name.length > 255) {
      errors.name = resources.validation.requiredMax255Characters;
    }
    if (!values.email) {
      errors.email = resources.validation.emailIsRequired;
    } else if (values.email && !emailIsValid(values.email)) {
      errors.email = resources.validation.incorrectEmailAdres;
    } else if (values.email && values.email.length > 255) {
      errors.email = resources.validation.requiredMax255Characters;
    }
    if (values.password && values.password.length > 255) {
      errors.password = resources.validation.requiredMax255Characters;
    }
    if (values.repeatPassword && values.repeatPassword.length > 255) {
      errors.repeatPassword = resources.validation.requiredMax255Characters;
    }
    if (!values.repeatPassword) {
      errors.repeatPassword = resources.validation.requiredTheSamePasswords;
    } else if (values.password !== values.repeatPassword) {
      errors.repeatPassword = resources.validation.requiredTheSamePasswords;
    }

    const specChar = new RegExp(`[!"#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~]`, "g");

    const specMatches = values.password.match(specChar);
    if (
      !reg.test(values.password) ||
      !Array.isArray(specMatches) ||
      specMatches.length < 2
    ) {
      errors.password = resources.validation.passwordCondition;
    }
    if (!values.policy) {
      errors.policy = resources.validation.required;
    }

    return errors;
  },

  handleSubmit: (
    values: IFormValues,
    bag: FormikBag<IFormProps & IReferrer, IFormValues>
  ) => {
    const data = { ...values, referrer: bag.props.referrer };

    registerUser(data, bag.props.nextStep, bag.setFieldValue);
  },
  enableReinitialize: true,
  validateOnBlur: true,
  validateOnChange: false,
})(InnerRegisterForm);

export default RegisterForm;

const registerUser = async (
  data: IRegisterData,
  nextStep: (id: number) => void,
  setFieldValue: (field: any, errorMessage: boolean) => void
): Promise<void> => {
  setFieldValue("loading", true);

  const obj: IRegisterUser = {
    name: data.name,
    email: data.email,
    password: data.password,
    skype: data.skype,
    telegram: data.telegram,
    referrer: data.referrer,
  };

  await createUser(obj).then((response) => {
    setFieldValue("loading", false);
    if (response) {
      response !== null && nextStep(response.data.id);
    }
  });
};
