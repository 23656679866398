import dashboardIcon from "../components/navigation/images/dashboard.svg";
import offersIcon from "../components/navigation/images/offers.svg";
import settingsIcon from "../components/navigation/images/settings.svg";
import statisticIcon from "../components/navigation/images/statistic.svg";

import { generateMenuItem } from "../helpers/MenuItemGenerator";
import { navPath } from "../common/ResourceDef";
import { resources } from "../common/Resources";
import { UserPermissionType } from "../models/IAppUserInfoModel";
import {hasPermission} from "../authorizations/AuthorizationLogic";

// =========
// main menu
// =========
export const getMenuItems = (permissions: number[]): any[] => {
  let menuItems = [];

  permissions.includes(UserPermissionType.DashboardView) &&
    menuItems.push(
      generateMenuItem(
        navPath.dashboard,
        resources.navigationItems.dashboard,
        navPath.dashboard,
        { type: "custom", source: dashboardIcon }
      )
    );
  permissions.includes(UserPermissionType.OffersListView) &&
    menuItems.push(
      generateMenuItem(
        navPath.offers,
        resources.navigationItems.offers,
        navPath.offers,
        { type: "custom", source: offersIcon }
      )
    );
  hasPermission(UserPermissionType.StatisticsView) &&
  menuItems.push(
      generateMenuItem(
          "statistics",
          resources.navigationItems.statistics,
          "statistics",
          { type: "custom", source: statisticIcon }
      )
  );
  permissions.includes(UserPermissionType.ProfileView) &&
    menuItems.push(
      generateMenuItem(
        navPath.profile,
        resources.subNavigationItem.settings,
        navPath.profile,
        { type: "custom", source: settingsIcon }
      )
    );
  // permissions.includes(UserPermissionType.LeadsView) &&
  //   menuItems.push(
  //     generateMenuItem(
  //       navPath.leads,
  //       resources.navigationItems.leads,
  //       navPath.leads,
  //       { type: "ant", source: "area-chart" }
  //     )
  //   );


  return menuItems;
};
