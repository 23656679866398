import * as React from "react";
import {
  checkDictionaries,
  checkDictionariesInterval,
} from "../../../helpers/DisctionariesHelper";
import { connect, MapDispatchToProps } from "react-redux";
import { Dispatch } from "redux";
import { getTotalBalance } from "../../../services/finance/FinanceService";
import { getUserInf } from "../../../services/m29_users_management/GeneralUserService";
import { history } from "../../../helpers/History";
import { IAppState } from "../../../store";
import { IAppUserInfoModel } from "../../../models/IAppUserInfoModel";
import { IChangeLang } from "../../../models/IChangeLang";
import { Icon, Menu } from "antd";
import { IFormHeaderContent } from "./interfaces/IFormHeaderContent";
import { ILogoutAppUser } from "../../../common/interfaces/ILogoutAppUser";
import { ISetAppUserInfo } from "../../../common/interfaces/ISetAppUserInfo";
import { ISetBlockUi } from "../../../common/interfaces/ISetBlockUi";
import {
  logoutAppUser,
  setAppUserInfo,
  setBlockUi,
} from "../../../reducers/CommonReducer";
import { refreshTokenMethod, setHeader } from "../../../helpers/Authentication";
import "../styles/HeaderStyle.css";
import { USER_ROLES } from "../../../common/enums/UserRoles";
import questionIcon from "./images/question-icon.svg";
import questionGrayIcon from "./images/question-gray-icon.svg";
import menu from "./images/menu-logo.svg";
import logoMobile from "./images/logo-mobile.svg";
import { HeaderLangPicker } from "../components/headerLangPicker/HeaderLangPicker";
import { getMenuItems } from "../../MenuItems";
import {
  renderMenuItem,
  renderSubMenu,
} from "../../../components/navigation/menu/NavigationMenu";
import { Link } from "react-router-dom";
import { logout } from "../../../services/AuthenticationServices";

class FormHeaderContent extends React.Component<
  IFormHeaderContent &
    IChangeLang &
    ISetAppUserInfo &
    ISetBlockUi &
    ILogoutAppUser
> {
  state = {
    current: "",
    rotate: 0,
    intervalDictionary: undefined,
    intervalToken: undefined,
    total_balance: 0,
    account_manager: {} as any,
    random_key: Math.random(),
    isOpenedMobileNavigation: false,
    navigationItems: Array<any>(),
  };

  componentDidMount = async () => {
    const permissions = this.props.permissions ? this.props.permissions : [];

    this.setState({ navigationItems: getMenuItems(permissions) });

    const intervalDictionary = setInterval(
      checkDictionariesInterval,
      60 * 60 * 1000
    );
    this.setState({ intervalDictionary: intervalDictionary });

    const intervalToken = setInterval(
      this.setTokenCheckInterval,
      10 * 60 * 1000
    );
    this.setState({ intervalToken: intervalToken });

    await this.getAppUserInfo();
    await checkDictionaries();

    refreshTokenMethod(
      this.props.setAppUserInfo,
      this.props.logoutAppUser,
      this.props.appUserInfo ? this.props.appUserInfo : {}
    );

    if (this.props.appUserInfo && this.props.appUserInfo.id_company) {
      this.getTotalBalance();
    }
    // initWebSocket();
  };

  UNSAFE_componentWillReceiveProps = (props: any) => {
    const permissions = this.props.permissions ? this.props.permissions : [];

    this.setState(
      {
        navigationItems: getMenuItems(permissions),
      },
      () => this.forceUpdate()
    );
  };

  componentWillUnmount = () => {
    // closeWebSocket();
    clearInterval(this.state.intervalDictionary);
    clearInterval(this.state.intervalToken);
  };

  onOpenMobileNavigation = () => {
    this.setState((prevState: any) => ({
      ...prevState,
      isOpenedMobileNavigation: !prevState.isOpenedMobileNavigation,
    }));

    const freezeBodyClasses = [
      "touch-none",
      "overflow-hidden",
      "overscroll-none",
    ];

    if (!this.state.isOpenedMobileNavigation) {
      document
        .getElementsByClassName("ant-layout")[1]
        .classList.add(...freezeBodyClasses);
    } else {
      document
        .getElementsByClassName("ant-layout")[1]
        .classList.remove(...freezeBodyClasses);
    }
  };

  public render() {
    return (
      <header className="relative container-fluid h-90 bg-gradient-to-r from-light-blue to-navy-blue px-10 py-5">
        <div className="flex items-center justify-between">
          <div className="hidden sm:block">
        {/*    <HeaderLangPicker
              changeLang={this.props.changeLang}
              handleUpdate={this.handleUpdate}
            />*/}
          </div>

          <div className="w-full sm:w-auto flex items-center gap-5">
            <a
              href="!#"
              className="flex max-w-max p-1 border-2 rounded-full border-blue hidden sm:flex bg-gradient-to-r from-transparent hover:from-blue hover:to-aquamarine"
            >
              <img
                className="pl-3 pr-3 border-r border-gray"
                src={questionIcon}
                alt="question-icon"
              />
              <span className="text-white pl-3 pr-3 border-l-1 border-gray-900 block font-normal">
                Need help?
              </span>
            </a>
            <div className="h-14 w-20 cursor-pointer hidden sm:flex bg-user-logo bg-no-repeat bg-center hover:bg-user-logo-active" />
            <div className="flex justify-between block sm:hidden w-full">
              <img src={logoMobile} alt="menu" />
              <img
                src={menu}
                alt="menu"
                onClick={this.onOpenMobileNavigation}
                className={`transform ${
                  this.state.isOpenedMobileNavigation ? "rotate-90" : ""
                }`}
              />
            </div>
          </div>
        </div>
        {this.state.isOpenedMobileNavigation && (
          <div className="absolute left-0 top-79 w-full h-screen bg-dark-blue">
            <div className="mt-8 mx-6 pb-6 mb-6 flex justify-between items-center border-b border-blue">
              <div className="flex items-center">
                <div className="h-14 w-14 mr-2 cursor-pointer bg-user-logo bg-no-repeat bg-center" />
                <span className="text-white text-xxs">John Doe</span>
              </div>
              <HeaderLangPicker
                changeLang={this.props.changeLang}
                handleUpdate={this.handleUpdate}
              />
            </div>
            <div className="customAnt--menu ">
              <Menu
                mode="inline"
                theme="dark"
                style={{
                  backgroundColor: "#222849",
                }}
              >
                {this.state.navigationItems.map((navigationItem) =>
                  navigationItem.isMenuSubItem
                    ? renderSubMenu(navigationItem)
                    : renderMenuItem(navigationItem)
                )}
                <Menu.Item
                  key="logout"
                  className="navMenuItem"
                  style={{
                    backgroundColor: "#222849",
                  }}
                  onClick={() => this.logoutUser()}
                >
                  <Link to="/" style={{ fontSize: "16px" }}>
                    <Icon
                      type="logout"
                      style={{ fontSize: "32px" }}
                      className="anticon"
                    />
                    <span>Logout</span>
                  </Link>
                </Menu.Item>
              </Menu>
            </div>
            <div className="fixed bottom-0 bg-rhino w-full py-4 pl-4">
              <a href="!#" className="flex items-center">
                <img
                  src={questionGrayIcon}
                  alt="question-gray-icon"
                  className="mr-2"
                />
                <span className="text-white text-xxs">Need help?</span>
              </a>
            </div>
          </div>
        )}
      </header>
    );
  }

  setTokenCheckInterval = () => {
    refreshTokenMethod(
      this.props.setAppUserInfo,
      this.props.logoutAppUser,
      this.props.appUserInfo ? this.props.appUserInfo : {}
    );
  };

  handleClick = (e: any) => {
    if (e.key === "profile") {
      history.push(`/${e.key}`);
    }
  };

  handleUpdate = () => {
    this.forceUpdate();
    this.setState({ random_key: Math.random() });
  };

  getAppUserInfo = async () => {
    setHeader();

    await getUserInf().then((response) => {
      if (response) {
        const {
          id_country,
          id_account_manager,
          country_name,
          id_users_role,
          account_manager,
          id_company,
        } = response;

        const newState = {
          ...this.props.appUserInfo,
          id_country,
          id_account_manager,
          country_name,
          id_users_role: id_users_role,
          account_manager,
          id_company,
        };

        this.props.setAppUserInfo(newState);

        this.setState({ account_manager: response.account_manager });
      }
    });
  };

  getTotalBalance = () => {
    this.setState({ total_balance_loading: true });

    getTotalBalance("")
      .then((total_balance) => this.setState({ total_balance }))
      .finally(() => this.setState({ total_balance_loading: false }));
  };

  displayBalance = (loading: boolean, value: number) =>
    loading ? (
      <Icon type="sync" spin={loading} />
    ) : (
      <>{`${(value / 100).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}`}</>
    );

  logoutUser = async () => {
    setHeader();
    await logout();
    this.props.logoutAppUser();

    localStorage.clear();
    setHeader();

    history.push(`/`);
    window.scrollTo(0, 0);
  };
}

const mapStateToProps = (state: IAppState): IFormHeaderContent => ({
  appUserInfo: state.commonState.appUserInfo,
  isAuthenticated: state.commonState.isAuthenticated,
  isCCSimplified:
    state.commonState.appUserInfo.id_users_role === USER_ROLES.CC_SIMPLIFIED,
  permissions: state.commonState.appUserInfo.permissions,
});

const mapDispatchToProps: MapDispatchToProps<
  ISetAppUserInfo & ISetBlockUi & ILogoutAppUser,
  {}
> = (dispatch: Dispatch) => ({
  setAppUserInfo: (appUserInfoModel: IAppUserInfoModel) => {
    dispatch(setAppUserInfo(appUserInfoModel));
  },
  setBlockUi: (blockUi: boolean) => {
    dispatch(setBlockUi(blockUi));
  },
  logoutAppUser: () => {
    dispatch(logoutAppUser());
  },
});

const HeaderContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormHeaderContent);

export default HeaderContent;
