import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setActiveMenuItem } from "../../reducers/CommonReducer";
import { StatisticTable, TableSidebar } from "./components";
import {
  getCampaignsStatistics,
  getStatisticsCampaigns,
} from "../../services/m55_leads_statistics/LeadsStatisticsService";
import moment from "moment";

export const StatisticsView = () => {
  const { param } = useParams<{ param: string }>();
  const dispatchRx = useDispatch();
  const [filters, setFilters] = useState<{ [key: string]: number | string }>({
    month: moment().month() + 1,
    year: moment().year(),
  });

  const statisticsCampaigns = useQuery(
    "statistics-campaigns",
    getStatisticsCampaigns,
    {
      onSuccess: (data) => {
        setFilters((prev) => ({ ...prev, campaign_id: data[0].campaign_id }));
      },
    }
  );

  const leadsStatistics = useQuery(
    "campaign-statistics",
    () => getCampaignsStatistics(filters),
    {
      enabled: !!filters.campaign_id,
    }
  );

  const onChangeFilter = (filter: string, value: number | string) => {
    setFilters((prev) => ({ ...prev, [filter]: value }));
  };

  useEffect(() => {
    if (filters.campaign_id) {
      leadsStatistics.refetch();
    }
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    const activeMenuItem = param ? `statistics/${param}` : "statistics";
    dispatchRx(setActiveMenuItem(activeMenuItem));
  }, [dispatchRx, param]);

  if (statisticsCampaigns.isLoading || leadsStatistics.isLoading) {
    return null;
  }

  return (
    <div className="w-full h-full flex">
      <TableSidebar
        filters={filters}
        campaigns={statisticsCampaigns.data}
        onChangeFilter={onChangeFilter}
      />
      <StatisticTable
        data={leadsStatistics.data}
        filters={filters}
        campaigns={statisticsCampaigns.data}
        onChangeFilter={onChangeFilter}
      />
    </div>
  );
};
