import React from "react";

import arrowSelect from "./images/arrow-select-campaign.svg";

export const TableSidebar = ({
  filters,
  campaigns,
  onChangeFilter,
}: {
  filters: any;
  campaigns: any;
  onChangeFilter: any;
}) => (
  <section className="min-w-314 h-full bg-white-beige pt-10 hidden lg:block">
    <h1 className="pl-6 text-indigo text-sm font-semibold mb-6">
      Select your campaign
    </h1>
    <hr className="border-white" />
    <ol className="overflow-y-scroll h-345">
      {campaigns.map((campaign: any, index: number) => (
        <React.Fragment key={campaign.campaign_id}>
          <li
            className={`py-5 pr-5 pl-6 cursor-pointer ${
              filters.campaign_id === campaign.campaign_id
                ? "bg-light-gray"
                : ""
            }`}
            onClick={() => onChangeFilter("campaign_id", campaign.campaign_id)}
          >
            <span className="font-bold text-dark-gray text-xxs pb-1.5">
              {index + 1}. {campaign.product_name}
            </span>
            <div className="flex justify-between">
              <span className="font-normal text-dark-gray text-xxs">
                {campaign.campaign_name}
              </span>
              <img className="cursor-pointer" src={arrowSelect} alt="arrow" />
            </div>
          </li>
          <hr className="border-white" />
        </React.Fragment>
      ))}
    </ol>
  </section>
);
