import React from "react";
import { NavLink } from "react-router-dom";

import bgWizard from "./images/bg-wizard.svg";

export const WizardContent = () => {
  return (
    <section className="container-fluid h-full bg-white">
      <img
        className="relative z-10 w-full object-cover h-1/2"
        src={bgWizard}
        alt="bg-icon"
      />
      <div className="relative z-20 top-56 mx-auto px-2 xxl:px-0 -mb-56 lg:-mb-72 xxl:w-800 h-max flex flex-col text-center gap-y-10">
        <h1 className="text-lg md:text-lmf lg:text-lsm text-indigo font-bold">
          Mulai menghasilkan uang dengan Linknusa
        </h1>
        <p className="mx-auto px-2 xxl:px-0 max-w-347 md:max-w-502 xxl:w-502 text-min-xs md:text-xsm text-dark-gray font-normal">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
          interdum sapien vitae massa commodo, in consectetur diam vulputate.
          Cras at mi pharetra nunc scelerisque malesuada non id ante.
        </p>
        <div className="flex gap-1 justify-center">
          <span className="w-38 h-38 border-3 border-blue rounded-full text-indigo content-center text-base font-semibold">
            1
          </span>
          <span className="self-center w-3 h-3 bg-blue rounded-full"></span>
          <span className="self-center w-3 h-3 bg-blue rounded-full"></span>
          <span className="self-center w-3 h-3 bg-blue rounded-full"></span>
          <span className="w-38 h-38 border-3 border-blue rounded-full text-indigo content-center text-base font-semibold">
            2
          </span>
          <span className="self-center w-3 h-3 bg-blue rounded-full"></span>
          <span className="self-center w-3 h-3 bg-blue rounded-full"></span>
          <span className="self-center w-3 h-3 bg-blue rounded-full"></span>
          <span className="w-38 h-38 border-3 border-blue rounded-full text-indigo content-center text-base font-semibold">
            3
          </span>
          <span className="self-center w-3 h-3 bg-blue rounded-full"></span>
          <span className="self-center w-3 h-3 bg-blue rounded-full"></span>
          <span className="self-center w-3 h-3 bg-blue rounded-full"></span>
          <span className="w-38 h-38 border-3 border-blue rounded-full text-indigo content-center text-base font-semibold">
            4
          </span>
        </div>
      </div>
      <NavLink to="/wizard/landing">
        <div className="w-191 mx-auto pb-9">
          <button className="w-172 h-38 md:w-191 md:h-44 text-white text-min-xs md:text-xxs font-bold text-center rounded-full border-1 bg-gradient-to-r from-blue to-aquamarine hover:from-light-blue hover:to-navy-blue hover:text-white">
            MULAI BERIKLAN
          </button>
        </div>
      </NavLink>
    </section>
  );
};
