import React from "react";

export const StatisticTableMore = ({ row }: any) => {
  return (
    <>
      <tr className="w-full">
        <th className="pl-5 pr-3 2xl:pr-0 py-3 text-min-xs lg:text-xxxs font-bold text-dark-gray">
          Conversion rate
        </th>
        <th className="py-3 pr-3 2xl:pr-0 text-min-xs lg:text-xxxs font-bold text-dark-gray">
          CPA dynamic payout for 1 lead
        </th>
        <th className="pr-5 md:pr-0 py-3 text-min-xs lg:text-xxxs font-bold text-dark-gray">
          Your total payout
        </th>
      </tr>
      <tr className="w-full">
        <td className="pr-3 2xl:pr-0 pl-5 py-3 text-min-xs lg:text-xxxs font-normal text-dark-gray">
          {row.conversion_rate}
        </td>
        <td className="py-3 pr-3 2xl:pr-0 text-min-xs lg:text-xxxs font-normal text-dark-gray">
          {row.cpa_for_1_lead}
        </td>
        <td className="pr-5 md:pr-3 2xl:pr-0 py-3 text-min-xs lg:text-xxxs font-normal text-dark-gray">
          {row.total_payout}
        </td>
      </tr>
    </>
  );
};

export default StatisticTableMore;
