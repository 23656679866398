import React, { useState } from "react";
import { resources } from "../../common/Resources";
import { Modal } from "antd";
import { createTargetUrlCampaign } from "../../services/TargetUrlCamaigns";
import { useDispatch } from "react-redux";
import { setWizard } from "../../reducers/CommonReducer";

const { confirm } = Modal;

export type GenerateUrlFormPropsType = {
  landing: number[];
  productId?: number | string;
  reRenderTable?: () => void;
  buttonName: string;
  placeholderName: string;
  prelanding?: number[];
};

export const GenerateUrlForm = (props: GenerateUrlFormPropsType) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    errorsVisible: 0,
    campaignName: "",
  });
  const handleSubmit = (e: any): void => {
    e.preventDefault();

    confirm({
      title: resources.labels.are_you_sure,
      content: resources.labels.do_you_want_to_generate_this_campaign,
      okText: resources.buttons.yes,
      okType: "primary",
      cancelText: resources.buttons.no,
      className: "create-campaign-modal",
      centered: true,
      onOk: () => {
        validation() ? submit() : displayErrors();
      },
      onCancel() {},
    });
  };

  const validation = (): boolean => {
    return props.landing.length > 0;
  };

  const displayErrors = (): void => setState({ ...state, errorsVisible: 1 });

  const submit = async () => {
    const data = {
      productId: Number(props.productId),
      lp: props.landing,
      pre: props.prelanding!,
      name: state.campaignName,
      redirectType: "302",
      redirectRation: "auto",
    };

    await createTargetUrlCampaign(data).then((response: any) => {
      setState({
        campaignName: "",
        errorsVisible: 0,
      });
      dispatch(setWizard({ url: response.urls.tracker }));
    });
  };

  const onInputChange = (event: any): void =>
    setState({ ...state, campaignName: event.target.value });

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-7">
        <div
          className={`${props.buttonName === "Save" ? "items-center" : "items-end"} flex  xm:flex-col md:flex md:flex-row md:items-start gap-2`}
        >
          <div className="w-full md:w-382 flex flex-col">
            <input
              className="placeholder-gray-500 placeholder-opacity-50 border rounded-md border-lite-gray py-2 px-5 w-full text-xxs font-normal mb-2"
              placeholder={props.placeholderName}
              type="text"
              required
              value={state.campaignName}
              onChange={onInputChange}
            />
            <span className="block self-end text-red text-min font-normal">
              *please add correct name
            </span>
          </div>
          <div className="w-125 h-40 relative bg-gradient-to-r bg-no-repeat from-blue to-aquamarine rounded-md">
            <button
              className="w-121 h-36 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hover:bg-transparent bg-blue-gray text-white font-semibold text-min-xs rounded-md"
              type="submit"
            >
              {props.buttonName}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
